._info_box{

}
._info_box__title{
    font-size: calcRem(40);
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: calcRem(40);

    &--red{
        color: $color-red-main;
    }

    &--small{
        font-size: calcRem(30);
    }

    @include media-breakpoint-down(lg) {
        font-size:font-vw-lg(32);

        &--small{
            font-size: calcRem(24);
        }
    }
    @include media-breakpoint-down(md) {
        font-size:font-vw-md(24);
        margin-bottom:5vw;
        &--small{
            font-size: calcRem(18);
        }
    }
    @include media-breakpoint-down(sm) {
        font-size:font-vw-sm(24);
    }
    @include media-breakpoint-down(xs) {
        font-size:calcRem(16);
    }
}
._info_box__des{
    //@extend ._font_des--normal;
    font-size: calcRem(16);
    line-height: 1.4;
    margin-bottom: calcRem(40);
    color:$color-gray-6;

    @include media-breakpoint-down(md) {
        font-size: calcRem(16);
        margin-bottom:8vw;
    }
    @include media-breakpoint-down(sm) {
        font-size: calcRem(14);
        //line-height: 1.4;
        //margin-bottom:5vw;
    }
    @include media-breakpoint-down(xs) {
        font-size: calcRem(12);
    }
    
}
._info_box__btn{
    //margin-top: calcRem(40);
    @include media-breakpoint-down(sm) {
        font-size: calcRem(13);
    }
    @include media-breakpoint-down(xs) {
        //padding:calcRem(13);
        font-size: calcRem(12);
        width: 100%;
    }
}
