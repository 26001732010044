._banner_kv{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    //z-index: 5;

    // @include media-breakpoint-down(lg) {
    //     font-size:font-vw-lg(60);
    // }
    // @include media-breakpoint-down(md) {
    //     font-size:font-vw-md(50);
    // }
    @include media-breakpoint-down(sm) {
        height: 100vw;
    }
    @include media-breakpoint-down(xs) {
        //font-size:calcRem(20);
    }
}
._banner_kv__bg{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    transform:translate3d(0px, 0px, 0px) scale(1,1);
}
._banner_kv__bgZoom{
    width: 100%;
    height: 100vh;
    position: relative;
    @include media-breakpoint-down(sm) {
        height: 100vw;
    }
    //transition-duration: 8s;
    //transform: scale(1.3,1.3);
    // &.active{
    //     transform: scale(1,1);
    // }
}
._banner_kv__img{
    display: block;
    width: 100%;
    height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-position:center 0vh;
    position: absolute;
    overflow: hidden;
    top:0;
    left:0;
    
    @include media-breakpoint-down(sm) {
        height: 100vw;

    }
    //filter: unquote("grayscale(100%)");
}

._banner_kv__iframe{
    transform: scale(1.3,1.3);
    position: absolute;
    top:-100%;
    right:-100%;
    bottom:-100%;
    left:-100%;
    width:100vw;
    height:100vh;
    margin:auto;

    @include media-breakpoint-down(sm) {
        height: 100vw;
    }

    iframe{
        width:100%;
        height: 100%;
    }
    &::after{
        content:'';
        display:block;
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color:#000;
        transition-duration: 1s;
        transition-delay: 1.5s;
        opacity: 1;
    }

    &.playing{
        &::after{
            opacity: 0;
            visibility: hidden;
        }
    }

    &.hide{
        display: none;
    }

    @include media-breakpoint-down(lg) {
        
    }
    @include media-breakpoint-down(md) {
        
    }
    @include media-breakpoint-down(sm) {
        width:250vw;
        //line-height: 1.4;
    }
    @include media-breakpoint-down(xs) {
        
    }
}
._banner_kv__bgImg{
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    position: absolute;
    //max-height: calc(100vh + 300px);
    top:-1000%;
    right:-1000%;
    bottom:-1000%;
    left:-1000%;
    margin:auto;
    object-fit: cover;
    @include media-breakpoint-down(sm) {
        width:100%;
    }
}
._banner_kv__slide{
    height: 100%;
}
._banner_kv__patten{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    background-size: 100%;
    pointer-events: none;
    margin-top: -50vh;
    svg{
        width: 100%;
    }

}
._banner_kv__container{
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
    @extend .align-items-center;
    min-height: 100%;
    z-index:6;
}
._banner_kv__info{
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
    align-self: flex-start;
    position: relative;
    padding: calcRem(100) 0;
    opacity: 0;
    visibility: hidden;
    transition-duration: .3s;

    &--center{
        @extend .align-items-center;
        @extend .align-self-center;
        ._banner_kv__headLine{
            
            text-align: center;
            span{
                opacity: 0;
            }
        }
    }




    &.active{
        opacity: 1;
        visibility:visible;
    }
    
}
._banner_kv__name{
    //@extend .align-self-center;
    color: $color-white;
    margin-bottom: calcRem(20);
    font-weight: bold;
    transform: translateY(50%);
    opacity: 0;
    transition-delay: 1.2s;
    transition-duration: .5s;
    &.active{
        transform: translateY(0%);
        opacity: 1;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom:3vw;
        font-size:calcRem(12);
    }
    @include media-breakpoint-down(xs) {
        
    }
}
._banner_kv__headLine{
    font-weight: bold;
    color: $color-red-main;
    display: inline-block;
    line-height: 1.2;
    text-shadow: rgba(0,0,0,.13) 0 5px 20px;

    &--large{
        @extend ._font_title--xl;
    }
    &--middle{
        @extend ._font_title--md;
    }
    &--middleSmller{
        @extend ._font_title--sm;
    }


    &--white{
        color: $color-white;
    }

    @include media-breakpoint-down(lg) {
        font-size:font-vw-lg(60);
    }
    @include media-breakpoint-down(md) {
        font-size:font-vw-md(50);
    }
    @include media-breakpoint-down(sm) {
        font-size:font-vw-sm(40);
        //line-height: 1.4;
    }
    @include media-breakpoint-down(xs) {
        font-size:calcRem(20);
    }
}
._banner_kv__red{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    @include media-breakpoint-down(sm) {
        height: 100vw;
        overflow: hidden;
    }
}
._banner_kv__red-1{
    position: absolute;
    left:0;
    width: 85%;
    z-index:6;
    opacity: .7;
    img{
        position: relative;
        width:100%;
    }
    @include media-breakpoint-down(sm) {
        width:0%;
    } 
}
._banner_kv__red-2{
    position: absolute;
    right:0;
    width: 35%;
    top:60%;
    z-index:6;
    img{
        position: relative;
        width:100%;
    }
    @include media-breakpoint-down(sm) {
        top:40%;
        width:60%;
    } 

    
}   
._banner_kv__scrollDown{
    position: absolute;
    bottom:100px;
    margin:auto;

    &.hide{
        opacity: 0;
        visibility: hidden;
    }

    @include media-breakpoint-down(sm) {
        bottom:10vw;
    }

    @include media-breakpoint-down(xs) {
        font-size:calcRem(12);
    }

    @media screen and  (min-width: 800px) and (max-height: 650px) {
       display: none;
    }
}