@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size, $length in $font-sizes {
      @if $size != 0 {
        .fs#{$infix}-#{$size} { font-size: $length + rem !important; }
      }
    }
  }
}