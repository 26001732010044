
._sidePop{
    

    position: fixed;
    top:0;
    right:0;
    z-index: 999;
    color:$color-white;
    transition-duration: .3s;
    &.active{
        background-color:rgba(0,0,0,.6);
        ._sidePop__box{
            transform: translateX(0);
        }
    }

    &--large{
        ._sidePop_content{
            width:50%;
        }
        ._sidePop__box{
            &::before,&::after{
                right:50%;
            }
        }
        ._sidePop__boxSide{
            right: 50%;
        }
    }


    &--largeMore{
        ._sidePop_content{
            width:80%;
            padding-right: calc( 5% + 20% - 15px );
        }
        ._sidePop__boxSide{
            right: 80%;
        }
        ._sidePop__box{
            &::before,&::after{
                right:80%;
            }
        }
        ._sidePop__photo{
            background:transparent;
            border:none;
            box-shadow: none;
        }

        @include media-breakpoint-down(lg) {
                
        }
        @include media-breakpoint-down(md) {
            ._sidePop_content{
                width:100%;
                padding-left:7%;
                padding-right:7%;
            }
            ._sidePop__box{
                &::before,&::after{
                    right:100%;
                }
            }
            ._sidePop__boxSide{
                right:100%;
            }
    
        }
        @include media-breakpoint-down(sm) {
            padding-left: 5%;
            padding-right: 5%;
            //display: none;
        }
        @include media-breakpoint-down(xs) {
           
        }
    }

    &--white{
        
        ._sidePop_content{
            background-color: $color-white;
            color: #585858;
        }
        ._sidePop__box{
            
            &:before,&::after{
                border-color:  transparent transparent $color-white transparent;
            }
        }
        ._header_trigger__iconLine--close:after, ._header_trigger__iconLine--close:before{
            background-color:#696969;
        }
        ._sidePop__photo{
            box-shadow: none;
        }
        ._sidePop__boxSide{
            background-color: $color-white;
        }
    }
  
}
._sidePop__boxSide{
    width:calcRem(200);
    height: 100%;
    background-color: $color-red-main;
    opacity: .6;
    position: absolute;
    right:40%;
}
._sidePop__box{
    // @extend .d-flex;
    // @extend .justify-content-end;
    // @extend .align-items-stretch;
    width: 100vw;
    height:100vh;
    
    text-align: right;
    
    transition-duration: .5s;
    transform: translateX(calc(150vw + 100vh +  50vh + 50vw));
    @include media-breakpoint-down(sm) {
        transform: translateX(calc(300vh));
    }

    &::before,&::after{
        content:'';
        display: block;
        position: absolute;
        top:0;
        right:40%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 100vh 200vh;
        border-color:  transparent transparent $color-red-main transparent;
        @include media-breakpoint-down(lg) {
            right: 50%;
        }
        @include media-breakpoint-down(md) {
            //right: 50%;
        }
        @include media-breakpoint-down(sm) {
            right: 100% !important;
            //display: none;
        }
        @include media-breakpoint-down(xs) {
           
        }
    }

    &::before{
        opacity: .6;
        transform: translateX(calcRem(-200));
    }
    
}
._sidePop__title{
    font-size: calcRem(28);
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: calcRem(20);
    @include media-breakpoint-down(sm) {
        font-size: calcRem(20);
    }
    @include media-breakpoint-down(xs) {
        font-size: calcRem(16);
    }
}
._sidePop__photo{
    margin-bottom:calcRem(30);
    margin-top:calcRem(10);
    display: block;
    box-shadow:0px 40px 80px 0px rgba(0, 0, 0, 0.1);
    background-color: $color-white;
    border:10px solid $color-white;
    height: auto;
    width: 100%;
    &--noneBorder{
        border:none;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom:5vw;
        margin-top:5vw;
    }
    @include media-breakpoint-down(xs) {
        //font-size: calcRem(16);
    }
}
._sidePop_content{
    position: relative;
    display: inline-block;
    text-align: left;
    overflow-y: auto;
    width: 40%;
    height: 100vh;
    background-color: $color-red-main;
    padding:calcRem(120) 8% calcRem(120) 3%;
    @include media-breakpoint-down(lg) {
        padding: $main-pd-y-lg 5%;
        width: 50%;
    }
    @include media-breakpoint-down(md) {
        
    }
    @include media-breakpoint-down(sm) {
        width: 100% !important;
    }
    @include media-breakpoint-down(xs) {
       
    }
}
._sidePop__em{
    font-size:calcRem(14);
    margin-bottom: calcRem(20);
    display: block;
    @include media-breakpoint-down(sm) {
        margin-bottom:3vw;
        font-size:calcRem(12);
    }
    @include media-breakpoint-down(sm) {
        margin-bottom:2vw;
    }
}
._sidePop__name{
    font-size:calcRem(48);
    margin-bottom: calcRem(30);
    font-weight: bold;
    //letter-spacing: 5px;
    @include media-breakpoint-down(sm) {
        font-size:calcRem(32);
        margin-bottom: 10%;
    }
    @include media-breakpoint-down(xs) {
        font-size:calcRem(20);
        margin-bottom: 6%;
    }
}
._sidePop__des{
    font-size:calcRem(16);
    line-height: 1.4;
    opacity: .8;
    @include media-breakpoint-down(sm) {
        font-size:calcRem(14);
    }
    @include media-breakpoint-down(xs) {
        font-size:calcRem(12);
    }
}
._sidePop__bgn{
    position: absolute;
    top:0;
    left:0;
    width:calc(60vw);
    height:100%;
    pointer-events: none;
    transform: translateX(-100%);
    opacity: .1;
    display: none;
    img{
        object-fit: cover;
        position: absolute;
        top:-1000%;
        right:-1000%;
        left:-1000%;
        bottom:-1000%;
        margin: auto;
        width: auto;
        height:auto;
        min-width: 100%;
        min-height: 100%;
        filter: unquote("grayscale(100%)");
        mix-blend-mode: luminosity;
        //opacity: .15;
    }
}
._sidePop__close{
    width:50px;
    position: absolute;
    right:50px;
    top:60px;
    @include media-breakpoint-down(sm) {
        width: 40px;
        right:5%;
        top:8vw;
    }
    @include media-breakpoint-down(xs) {
        width: 30px;
    }
}
._sidePop__closeLine{
    &::before,&::after{
        width:100%;
    }
}