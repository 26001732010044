._swiper_pagination{
    text-align: center;
    margin-top: 10px;
    .swiper-pagination-bullet{
        margin: 0 5px;
        outline: none !important;
    }
    .swiper-pagination-bullet-active{
        background-color: $color-red-main;
    }
}

.swiper-pagination-bullet{
    @include media-breakpoint-down(xs) {
        width:5px !important;
        height:5px !important;
    }
}