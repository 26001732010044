._select{
    position: relative;
    padding: calcRem(10);
    
    &::after{
        content:'';
        position: absolute;
        display: block;
        pointer-events: none;
        right:8px;
        top:0;
        bottom:0;
        margin: auto;
        width: calcRem(16);
        height: calcRem(16);
        background: url('/img/ui-arrow.svg') no-repeat center;
        background-size:100%;
    }
}
._select_list{
    background-color:transparent;
    border:none;
    appearance:none;
    padding-right: calcRem(20);
    line-height: 1.4;
    outline:none;

    @include media-breakpoint-up(800px) {
        font-size: calcRem(14);
    }
    @include media-breakpoint-up(480px) {
        font-size: calcRem(12);
    }

}