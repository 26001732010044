._nav{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height: 100vh;
  visibility: hidden;
  z-index: 10;
  &.active{
    visibility: visible;
    &::before{
      opacity: 1;
      visibility: visible;
    }
    ._nav_box{
      transform: translateX(0);
    }
    ._nav__triangle--alpha{
      transform: translateX(0%);
      @include media-breakpoint-down(sm) {
        opacity: 1;
      }
    }
    ._nav__menu__link{
      opacity: 1;
      transform: translateX(0);
      transition-duration: .5s;
      
      @for $i from 1 through 7 { 
        &:nth-child(#{$i}) {
          transition-delay: ($i+5)*.05s;
        }
      }
    }
    ._nav_language{
      transform: translateY(0);
      opacity: 1;
      transition-duration: .5s;
      transition-delay: .7s;
    }
  }
  &.navOut{
   // visibility:hidden;
    transition-duration: 0s;
    ._nav_box{
      transition-duration: 0s;
    }
  }
}
._nav_hit{
  position: absolute;
  top:0;
  left:0;
  width:100vw;
  height: 100vw;
}
._nav_box{
  @extend .d-flex;
  @extend .align-items-center;
  @extend .flex-wrap;
  position: absolute;
  right:0;
  top:0;
  height: 100vh;
  background-color: $color-red-main;
  width: 33.33%;
  //min-width: 400px;
  transition-duration: .5s;
  transform: translateX(calc(100vw + 50vh));
  @include media-breakpoint-down(md) {
    width: 90%;
    transform: translateX(calc(100vw + 150vh));
  }
}
._nav__triangle{
  position: absolute;
  top: 0;
  right: calc(100% - 1px);
  svg{
    height: 100vh;
    position: absolute;
    top:0;
    right:0;
    pointer-events: none;
  }

  &--alpha{
    opacity: .8;
    //transition-delay: .5s;
    transform: translateX(100%);
    transition-duration: .5s;
  }
}
._nav__out{
  width:calc(100vw);
  height: 100vh;
  position: absolute;
  left:100%;
  top:0;
  background-color: #fff;
  background-color: $color-red-main;
  &::before{
    content:'';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left:100%;
    border-style: solid;
    border-width: 100vh 200vh 0 0;
    border-color: $color-red-main transparent transparent transparent;
  }
}
._nav__menu{
  //margin-top:22%;
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}
._nav__menu__list{
  width: 100%;
  position: relative;
  z-index: 1;
  
  a{
    font-size: calcRem(26);
    color:$color-white;
  }
}
._nav__menu__link{
  line-height: 1.6;
  transition-duration: 0s;
  transform: translateX(20px);
  opacity: 0;
  transition-delay: 0s;
  a{
    display: inline-block;
    position: relative;
    &::after{
      content:'';
      display: block;
      height: 2px;
      width:0;
      transition-duration: .3s;
      background-color: $color-white;
      position: absolute;
      bottom:3px;
      left:0;
    }
    &:hover,&.active{
      &::after{
        width:100%;
      }
    }
  }

  
}



._nav_language{
  @extend .d-flex;
  position: relative;
  opacity: 0;
  transition-duration: 0s;
  transition-delay: 0s;
  transform: translateY(20px);
  ._nav_language__label{
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    border: 1px solid $color-white;
    border-radius: 100%;
    transition-duration: .3s;
    margin-right:calcRem(15);
    margin-top:calcRem(50);
    overflow: hidden;
    &::before{
      content:'';
      display: block;
      position: absolute;
      top:0;
      right:0;
      left:0;
      bottom:0;
      margin:auto;
      background-color:$color-white;
      width:100%;
      height: 100%;
      transition-duration: .3s;
      transform: scale(0,0);
      border-radius: 100%;
      
    }
    &:hover,&.active{
      cursor: pointer;
      background-color: $color-white;
      span{
        color:$color-red-main;
      }
      &::before{
        transform: scale(1.1,1.1);
      }
    }
  }
}
._nav_language__input{
  display: none;
  &:checked + label {
    
    background-color: $color-white;
    span{
      color:$color-red-main;
    }
    &::before{
      transform: scale(1.1,1.1);
    }
  }
}
._nav_language__btn{
  font-size: calcRem(12);
  white-space: nowrap;
  color:$color-white;
  font-weight: bold;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  transition-duration: .3s;
  &:not(.active):hover {
    //color: $color-white;
  }
  &.active {
    //color:#ffcc00;
  }
}

