@import url('https://fonts.googleapis.com/css?family=Noto+Serif+TC&display=swap');
// 引用外部套件樣式.
@import "../vendors/bootstrap-4.3.1/scss/bootstrap";

// 公用資源樣式.
@import "utilities/utilities";

// 複寫樣式.
@import "rewrite/rewrite";

// 組件樣式.
@import "partial/global",
        "partial/font",
        "partial/btn",
        "partial/container",
        "partial/editor",
        "partial/ckeditor",
        "partial/header",
        "partial/footer",
        "partial/nav",
        "partial/info",
        "partial/select",
        "partial/tab",
        "partial/card",
        "partial/loader",
        "partial/animation",
        "partial/main",
        "partial/banner",
        "partial/swiper",
        "partial/sidePop",
        "partial/transition";

// 單元頁面樣式.
@import "section/home";
@import "section/group";
@import "section/_experience";
@import "section/_works";
@import "section/_worksDetail";
@import "section/_news";
@import "section/_contact";