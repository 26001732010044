._experience{
    @include media-breakpoint-down(sm) {
        margin-bottom: 10vw; 
    }
}
._experience_banner__img{
   // background-image: url('/img/thumb/ex-banner.png');
}
._experience_bgn{
    position: absolute;
    top:0;
    left:0;
    width:100%;

    svg{
       width: 100%; 
    }
}
._experience_bgn__red{
    width: 40%;
}
._experience_bgn__gray{
    width: 60%;
    position: absolute;
    top:-10vw;
    right:0;
}
//info
._experience_info{
    position: relative;
    padding:calcRem(250) 0 0;
    color:$color-gray-5;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
        padding: $main-pd-y-lg 0 0;
    }
    @include media-breakpoint-down(md) {
        min-height: unset;
    }
    @include media-breakpoint-down(sm) {
        padding: 15vw 0 0; 
    }
    @include media-breakpoint-down(xs) {
       
    }
    
}
._experience_info__con{
    @extend .d-flex;
    @extend .flex-wrap;
    margin-bottom: 200px;
    @include media-breakpoint-down(lg) {
        margin-bottom: 10vw;
    }
}

._experience_client__boxBgn{
    overflow: hidden;
    position: relative;
    padding:calcRem(150) 0;
    @include media-breakpoint-down(sm) {
        padding: 15vw 0; 
    }
}

._experience_info__box{
    @extend .col-lg-6;
    @extend .col-12;
    @extend .px-lg-4;

    strong{
        font-weight: bold;
        display: inline-block;
        margin-bottom: calcRem(10);
    }
    // @extend .offset-lg-4;
    // margin-bottom: calcRem(130);
    // @include media-breakpoint-down(lg) {
        
    // }
    // @include media-breakpoint-down(md) {
    //     margin-bottom: 6vw;
    // }
    // @include media-breakpoint-down(sm) {
    //     padding: 0;
    // }
    @include media-breakpoint-down(xs) {
        strong{
            margin-bottom: 2vw;
        }
    }
}

._experience_info__bgn{
    position: absolute;
    top:0;
    width:100%;
    height: 100%; 

    svg{
        position: absolute;
        left:65%;
        top:40%;
        width: 35%;
    }
}
._experience_info__card{
    margin-bottom: calcRem(300);
    &--1{
        @extend .col-lg-6;
        @extend .col-md-9;
        @extend .offset-lg-1;
    }
    &--2{
        @extend .col-lg-6;
        @extend .col-md-9;
        @extend .offset-lg-4;
        @extend .offset-md-3;
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: 6vw;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 25vw;
    }
    @include media-breakpoint-down(sm) {
        padding:0;
        margin-bottom: 6vw;
    }
    @include media-breakpoint-down(xs) {
       
    }
}

$clientItemH:calcRem(70);
$clientItemBorder:calcRem(20);

._experience_client{
    //@include shadow-box;
    //background-color: $color-white;
   display: flex;
    //width: 100%;
    flex-wrap:wrap;
    margin:0 calcRem(-50);
    
}
._experience_client__bgn{
   
    display: block;
    background: url('/img/experience_client__bgn.png') no-repeat;
    background-size: cover;
    width:100%;
    position: absolute;
    height:100%;
    background-position:center -10vw;
    &::before{
        content:'';
        display: block;
        width:100%;
        height:100%;
        position: relative;
        background-color: rgba(0,0,0,.3);
    }
    @include media-breakpoint-down(sm) {
        background-size: auto 160%;
    }
    // @include media-breakpoint-down(lg) {
    //     //padding-bottom: 6vw;
    //     margin-bottom: -6vw;
    // }
    // @include media-breakpoint-down(xs) {
    //     background-size: auto 160%;
    // }
}
._experience_client__pop{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    z-index: -1;
    padding: calcRem(100) 0;
    overflow-y: auto;
    background-color:rgba(35,35,35,.92);
    visibility: hidden;
    opacity: 0;
    transition-duration: .3s;

    &.active{
        visibility: visible;
        opacity: 1;
        z-index: 9999;
    }
    ._experience_container{
        max-width: 1000px;
    }
    ._experience_client__head{
        padding:calcRem(30);
        border-bottom: 1px solid $color-gray-4;
    }
    ._experience_client__item{
        +._experience_client__item{
            border-left: none;
        }
        
        // border-right: 1px solid $color-gray-4;
        // border-bottom: 1px solid $color-gray-4;

        a{
            padding:10%;
        }
        @include media-breakpoint-down(xs) {
            width:50%;
        }
    }
}
._experience_client__close{
    margin-left: auto;
    position: absolute;
    width: 75px;
    height: 100%;
    right: 0;
    display: block;
    padding: 1.875rem;
    top: 0;
    cursor: pointer;
    &:hover{
        span{
            transform: rotate(0deg);
            &::after{
                transform: rotate(0deg);
            }
        }
    }
    span{
        position: absolute;
        display: block;
        width: 17px;
        height: 17px;
        transform: rotate(45deg);
        opacity: .5;
        top: 0;
        bottom: 0;
        margin: auto;
        transition-duration: .3s;
        &::before,&::after{
            content:'';
            display: block;
            width: 100%;
            height:2px;
            background: $color-gray-2;
            position: absolute;
            top:0;right:0;
            left: 0;bottom:0;
            margin: auto;
        }
        &::after{
            transition-duration: .3s;
            transform: rotate(90deg);
        }
    }
}
._experience_client__block{
    flex: 1 0 auto;
    max-width: 50%;
    padding: 0 calcRem(50);
    text-align: center;
    @include media-breakpoint-down(xs) {
        max-width: 100%;
        +._experience_client__block{
            margin-top: 15vw;
        }
    }
}
._experience_client__bodyCon{
    
}
._experience_client__head{
    //@extend .d-flex;
    position: relative;
    padding:calcRem(30);
    padding-right: calcRem(40);
    padding-bottom: 0;
   // border-bottom: 1px solid $color-gray-4;
    
    
}
._experience_client__cateIcon{
    margin-left: auto;
}
._experience_client__body{
    position: relative;
    @extend .d-flex;
    @extend .flex-wrap;
    //padding:20px;
    //padding-top: 0;
    overflow: hidden;
    //transition-duration: .7s;
    //margin-top: -$clientItemBorder;

    // &::after{
    //     content:'';
    //     display: block;
    //     width:100%;
    //     position: absolute;
    //     height: $clientItemBorder;
    //     background-color: #fff;
    //     left:0;
    //     bottom:0;
    // }
    
}
._experience_client__footer{
    text-align: center;
    padding:calcRem(30);
    margin-top: -1px;
    border-top: 1px solid $color-gray-4;
    &:hover{
        cursor: pointer;
    }
    @include media-breakpoint-down(sm) {
        padding:5%;
    }
}
._experience_client__cateTitle{
    font-size: calcRem(14);
    font-weight: bold;
    margin-bottom: calcRem(20);
    text-align: left;
}
._experience_client__cateIcon{
    span{
        position: relative;
        display: block;
        width:8px;
        height:8px;
        opacity: .5;
        &::before,&::after{
            content:'';
            display: block;
            width: 100%;
            height:2px;
            background: $color-gray-2;
            position: absolute;
            top:0;right:0;
            left: 0;bottom:0;
            margin: auto;
        }
        &::after{
            transition-duration: .3s;
            transform: rotate(90deg);
        }
    }
}
._experience_client__item{
    //border-bottom: 1px solid #d6d6d6;
    +._experience_client__item{
        //border-left: 1px solid $color-gray-4;
    }
    //@extend .col-lg-3;
    //@include creatCover(100%,90%);
    // margin-top:calcRem(20);
    // margin-right:calcRem(20);
    //height: $clientItemH;
    position: relative;
    display: inline-block;
    //padding:$clientItemBorder;
    width:100%;
    //margin-right: calcRem(10);
    // overflow: hidden;
    
    @include media-breakpoint-down(lg) {
       
    }
    @include media-breakpoint-down(md) {
        
    }
    @include media-breakpoint-down(sm) {
        
    }
    @include media-breakpoint-down(xs) {
        //width:50%;
        
    }
}
._experience_client__itemImg{
    position: relative;
    height: 100%;
    width:100%;
    background-size: contain;
    background-position:center;
    background-repeat: no-repeat;
    
    //box-shadow: 0 30px 40px 0 rgba(0,0,0,.1);
    //@include absolue-center;
    // position: absolute;
    // box-sizing: border-box;
    // //padding:calcRem(40);
    // overflow: hidden;
    // width:60%;
    // min-width: 100px;
    // top:50%;
    // left:50%;
    // transform: translate(-50%, -50%);
    img {
        opacity: .5;
        width:100%;
        transition-duration: .3s;
    }
    &:hover{
        img {
            opacity: 1;
        }
       
    }
}
._experience_client__itemLink{
    margin-top: calcRem(20);
    font-size: calcRem(15);
    /* margin: auto; */
    display: inline-block;
    position: relative;
    padding: 10px 0;
    border-bottom: 2px solid #8e8e8e;
 
    &::after{
        content:'';
        display: block;
        height:2px;
        background-color: $color-red-main;
        position: absolute;
        bottom:-2px;
        width: 0;
        transition-duration: .3s;
    }
    &:hover{
        &::after{
            width:100%;
        }
        
    }
    @include media-breakpoint-down(xs) {
        margin-top: 3vw;
    }
}
//award
._experience_award{
    
    //background-attachment: fixed;
    padding-bottom: 1px;
    background-position: center -25vw;
    //background-attachment: fixed;
    margin-bottom: calcRem(-60);
    position: relative;
    // &::before{
    //     content:'';
        
    // }
    @include media-breakpoint-down(md) {
        margin-bottom: -5vw;
    }
    @include media-breakpoint-down(sm) {
        //@function: -10vw;
    }

    &--normal{
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }
    }
}
._experience_award__bgn{
    display: block;
    background: url('/img/ex-award.png') no-repeat;
    background-size: cover;
    width:100%;
    position: absolute;
    height:100%;
    background-position:center -30vw;
    @include media-breakpoint-down(xs) {
        background-size: auto 160%;
    }
}
._experience_award__title{
    @extend ._font_title--md;
    padding:calcRem(220) 0 calcRem(280);
    color:$color-white;
    font-weight: bold;
    margin-left: calcRem(50);
    //text-align: center;
    
    @include media-breakpoint-down(lg) {
        padding: 15vw 0 20vw;
        margin-left:5vw;
        font-size:font-vw-lg(40);
    }
    // @include media-breakpoint-down(md) {
    //     font-size:font-vw-md(32);
    //     padding: 14vw 0 23vw;
    // }
    @include media-breakpoint-down(sm) {
        margin-left:0;
        font-size:font-vw-sm(24);
        padding: 22vw 0 27vw;
    }
    @include media-breakpoint-down(xs) {
        font-size:calcRem(18);
        padding: 25vw 0 30vw;
    }

    &--normal{
        padding:calcRem(220) 0;

        @include media-breakpoint-down(lg) {
            padding: 15vw 0;
        }
        @include media-breakpoint-down(sm) {
            padding: 22vw 0;
        }
        @include media-breakpoint-down(xs) {
            padding: 25vw 0;
        }
    }

}
._experience_award__list{
    

    margin-bottom: calcRem(-60);
    
}
._experience_award__row{
    // @extend .row;
    // @extend .no-gutters;
    width:100%;
    border-style:solid;
    border-width: 1px;
    border-color: $color-gray-4;
    background-color:$color-white;
    @include shadow-box;

    .swiper-wrapper{
        @extend .align-items-stretch
    }
    .swiper-slide{
        height: auto;
        +.swiper-slide{
            //border-left:1px solid $color-gray-4;
        }
    }
}
._experience_award__slide{
    height: auto;
    position: relative;
}
._experience_award__item{
    @extend .d-flex;
    @extend .align-items-center;
    @extend .flex-column;
    @extend .align-self-stretch;
    // @extend .col-lg-4;
    // @extend .col-sm-6;
    margin :0;
    //display:flex;
    height:100%;
    padding:calcRem(20);
    text-align: center;
    
    

    &:hover{
        a{
            color:$color-gray-3;
        }
    }
}
._experience_award__itemCover{
    
    box-sizing: border-box;
    padding:6.5%;
    overflow: hidden;
    width:75%;
    //margin-bottom: calcRem(15);

    @include media-breakpoint-down(sm) {
        //padding:2vw;
    }
    @include media-breakpoint-down(xs) {
        //padding:5vw;
    }
   
}
._experience_award__itemImg{
    @include creatCover(100%,100%);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    img {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        transition-duration: .7s;
    }
}
._experience_award__itemInfo{
    //padding:calcRem(10);
    color:$color-gray-3;
    display: block;
    text-align: center;
    margin-top: auto;
    @include media-breakpoint-down(md) {
        padding-left:0;
        font-size: calcRem(14);
    }
}
._experience_award__itemYear{
    display: block;
    
    font-weight: bold;
    color:$color-red-main;
    margin-bottom: calcRem(8);
    @include media-breakpoint-down(lg) {
       
    }
    @include media-breakpoint-down(md) {
        margin-bottom: .5vw;
    }
    @include media-breakpoint-down(sm) {
        font-size: calcRem(12);
    }
    @include media-breakpoint-down(xs) {
        
    }
}
._experience_award__itemName{
    font-size: calcRem(12);
    font-weight: bold;
    
    @include media-breakpoint-down(sm) {
        font-size: calcRem(12);
    } 
}
._experience_award__more{
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    width:100%;
    text-align: center;
    padding:calcRem(50);
    border:1px solid $color-gray-4;
    margin-top: -1px;
    transition-duration: .3s;
    font-weight: bold;
    min-height: calcRem(70);
    &:hover{
        cursor: pointer;
        color:$color-red-main;
    }
    @include media-breakpoint-down(md) {
        padding: 5vw;
        font-size: calcRem(14);
    }
    @include media-breakpoint-down(sm) {
        
    }
}
