// px to rem ($size = 要的px大小).
@function calcRem($size, $base:$font-size-base) {
	$remSize: $size / $base;
	//Default font size on html element is 100%, equivalent to 16px;
	@return #{$remSize}rem;
}
@function font-vw-lg($size, $base:$font-size-base) {
	$remSize: $size / $base * 1.5;
	@return #{$remSize}vw;
}

@function font-vw-md($size, $base:$font-size-base) {
	$remSize: $size / $base * 1.75;
	@return #{$remSize}vw;
}

@function font-vw-sm($size, $base:$font-size-base) {
	$remSize: $size / $base * 2.5;
	@return #{$remSize}vw;
}

@function font-vw-xs($size, $base:$font-size-base) {
	$remSize: $size / $base * 3.5;
	@return #{$remSize}vw;
}


@mixin creatCover($width,$height) {
	width:$width;
	position: relative;
	&::before{
		content:'';
		display: block;
		padding-top: $height;
	}
}

@mixin shadow-box {
	box-shadow: 0px 40px 80px 0px rgba(0,0,0,0.1);
}

@mixin absolue-center {
	position: absolute;
	top:-100%;
	left:-100%;
	right:-100%;
	bottom:-100%;
	margin:auto;
}