._footer{
    //border: 1px solid $color-gray-4;
    background: $color-white;
}
._footer_logo{
    width:100%;
    max-width: 138px;
    svg{
        width: 100%;
        height: auto;
    }
    @include media-breakpoint-down(sm) {
        width: 15%;
        //min-width:80px ;
    }
}
._footer_container{
    @extend .d-flex;
    @extend .container;
    @extend .align-items-center;
    padding: calcRem(130) 0;

    @include media-breakpoint-down(lg) {
        padding: 10vw 0;
    }
    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }
    @include media-breakpoint-down(xs) {
        padding: 15vw 0 5vw;
    }
}
._footer_left{
    @include media-breakpoint-down(sm) {
        width: 100%;
        //margin-bottom: calcRem(5);
    }
}
._footer_right{
    @extend .d-flex;
    @extend .align-items-center;
    font-size: calcRem(12);
    
    margin-left: auto;
    position: relative;
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
    @include media-breakpoint-down(xs) {
        margin-left:unset;
    }
}
._footer_right__copy{
    margin-right: calcRem(20);
    >span{
        transform-origin: 0 50%;
        display: block;
        white-space:nowrap;
        @include media-breakpoint-down(xs) {
            transform: scale(.85,.85);
        }
    }
    
}
._footer_right__strong{
    font-weight: bold;
    color:$color-red-main;
}
._footer_right__fb{
    @include creatCover(100%,100%);
    width: 34px;
    border:1px solid $color-gray-4;
    border-radius: 100%;
    @include media-breakpoint-down(sm) {
        margin-left:auto;
    }
    
    //margin-top: calcRem(10);
}
._footer_right__fbBtn{
    
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}