// // Color system

$primary:       #D98D36;
$secondary:     #ccc;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;
$black:         $black;

// kiiiick custom.
$black-1:#333;

$blue-1: #367DEE;

$orange-1: #FFF7EE;
$orange-2: #FFE9D0;
$orange-3: #EAC293;
$orange-4: #F7BD79;
$brown-1: #7F756B;
$brown-2: #B59F87;
$brown-3: #C6C1BD;
$brown-4: #EFD4B5;
$brown-5: #F3EEE9;
$brown-6: #F2EDE8;
$brown-7: #B2A185;

$gray-1: $gray-600;
$gray-2: #999;
$gray-3: #D8D8D8;
$gray-4: #979797;
$gray-5: #545454;
$gray-6: #F4F4F4;
$gray-7: #777;
$gray-8: #f9f9f9;
$gray-9: #e7e7e7;
$gray-10: #444444;
$gray-11: #4d4d4d;
$gray-12: #a4a4a4;
$gray-13: #e8e8e8;
$gray-14: #ededed;
$gray-15: #f2f2f2;


$facebook: #3C5A99;
$line: #13C339;
$google: #E73B2B;
$default-cover: $gray-8;

$theme-colors: ();

// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "black": $black,

    // kiiiick custom.
    "black-1": $black-1,

    "orange-1": $orange-1,
    "orange-2": $orange-2,
    "orange-3": $orange-3,
    "orange-4": $orange-4,

    "brown-1": $brown-1,
    "brown-2": $brown-2,
    "brown-3": $brown-3,
    "brown-4": $brown-4,
    "brown-5": $brown-5,
    "brown-6": $brown-6,
    "brown-7": $brown-7,

    "gray-1": $gray-1,
    "gray-2": $gray-2,
    "gray-3": $gray-3,
    "gray-4": $gray-4,
    "gray-5": $gray-5,
    "gray-6": $gray-6,
    "gray-7": $gray-7,
    "gray-8": $gray-8,
    "gray-9": $gray-9,
    "gray-10": $gray-10,
    "gray-11": $gray-11,
    "gray-12": $gray-12,
    "gray-13": $gray-13,
    "gray-14": $gray-14,
    "gray-15": $gray-15,

    "facebook": $facebook,
    "line": $line,
    "google": $google,
    "default-cover": $default-cover
  ),
  $theme-colors
);


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    p5: ($spacer * .15),
    1: ($spacer * .25),
    2: ($spacer * .5),
    2p5: ($spacer * .75),
    3: $spacer,
    3p5: ($spacer * 1.3),
    4: ($spacer * 1.5),
    4p25: ($spacer * 1.75),
    4p5:($spacer * 2.5),
    5: ($spacer * 3),
    5p5: ($spacer * 4),
    6: ($spacer * 4.5),
    7: ($spacer * 6),
    8: ($spacer * 7.5),
    9: ($spacer * 9),
    10: ($spacer * 10.5),
    11: ($spacer * 12.5)
  ),
  $spacers
);


$font-size-base: 16;
$font-sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$font-sizes: map-merge(
  (
    12: 12 / $font-size-base,
    13: 13 / $font-size-base,
    14: 14 / $font-size-base,
    16: 16 / $font-size-base,
    18: 18 / $font-size-base,
    20: 20 / $font-size-base,
    24: 24 / $font-size-base,
    28: 28 / $font-size-base,
    30: 30 / $font-size-base,
    32: 32 / $font-size-base,
    34: 34 / $font-size-base,
    36: 36 / $font-size-base,
    42: 42 / $font-size-base,
    44: 44 / $font-size-base,
    48: 48 / $font-size-base
  ),
  $font-sizes
);


// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    20: 20%,
    25: 25%,
    33: 33.33333%,
    40: 40%,
    50: 50%,
    60: 60%,
    66: 66.66666%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$border-sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$border-sizes: map-merge(
  (
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
    8: 8px,
    10: 10px,
    15: 15px,
    20: 20px
  ),
  $border-sizes
);

$max-widths: ();
// stylelint-disable-next-line scss/dollar-variable-default
$max-widths: map-merge(
  (
    1: 1022px,
    2: 1168px,
    3: 960px,
    4: 600px,
  ),
  $max-widths
);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grid-breakpoints: map-merge(
  (
    xxs: 0,
    xs:480,
    sm: 600px,
    md: 800px,
    lg: 1000px,
    xl: 1200px,
    xxl: 1500px,
  ),
  $grid-breakpoints
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 1200px,
  // sm2: 1200px,
  // md: 1200px,
  // lg: 1200px,
  // xl: 1200px,
);

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
// $font-family-sans-serif: "Microsoft JhengHei", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: "Noto Sans TC","Yu Gothic", "PingFang TC", "微軟正黑體", Arial, sans-serif;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .75;
$font-size-lg:                $font-size-base;

$headings-line-height:        1.5;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-focus-width: 0;
$input-btn-focus-color: rgba($component-active-bg, 0);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y:         .9rem;
$input-btn-padding-x:         .9rem;
$input-btn-font-size:         $font-size-base * .85;

$input-btn-padding-y-sm:      .6255rem;
$input-btn-padding-x-sm:      1.125rem;
$input-btn-font-size-sm:      $font-size-sm;

$input-btn-padding-y-lg:      1.75rem;
$input-btn-padding-x-lg:      1.75rem;
$input-btn-font-size-lg:      $font-size-lg;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;
$btn-font-family:             $input-btn-font-family;
$btn-font-size:               $input-btn-font-size;
$btn-line-height:             $input-btn-line-height;

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-font-size-sm:            $input-btn-font-size-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg;
$btn-font-size-lg:            $input-btn-font-size-lg;
$btn-line-height-lg:          $input-btn-line-height-lg;

$btn-focus-box-shadow: $input-btn-focus-box-shadow;

// Forms

$label-margin-bottom:                   1rem;

$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
$input-font-family:                     $input-btn-font-family;
$input-font-size:                       .8125rem;
$input-font-weight:                     $font-weight-base;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-font-size-sm:                    $input-btn-font-size-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-font-size-lg:                    $input-btn-font-size-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

$input-bg:                              $gray-8;
$input-disabled-bg:                     $gray-200;

$input-color:                           $gray-700;
$input-border-color:                    $gray-400;
$input-border-width:                    $input-btn-border-width;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075);

$input-border-radius:                   0;
$input-border-radius-lg:                0;
$input-border-radius-sm:                0;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              #858585;
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                $input-btn-focus-box-shadow;

$input-placeholder-color:               $gray-2;
$input-plaintext-color:                 $body-color;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
$input-height-inner-half:               calc(#{$input-line-height * .5em} + #{$input-padding-y});
$input-height-inner-quarter:            calc(#{$input-line-height * .25em} + #{$input-padding-y / 2});

$input-height:                          calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
$input-height-sm:                       calc(#{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border});
$input-height-lg:                       calc(#{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border});

$form-group-margin-bottom:              .5rem;


// Modals

// Padding applied to the modal body
$modal-inner-padding:               3rem;

$modal-dialog-margin:               .5rem;
$modal-dialog-margin-y-sm-up:       1.75rem;

$modal-title-line-height:           $line-height-base;

$modal-content-color:               null;
$modal-content-bg:                  $white;
$modal-content-border-color:        rgba($black, .2);
$modal-content-border-width:        $border-width;
$modal-content-border-radius:       0;
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5);
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5);

$modal-backdrop-bg:                 #F6F6F6;
$modal-backdrop-opacity:            .9;
$modal-header-border-color:         $border-color;
$modal-footer-border-color:         $modal-header-border-color;
$modal-header-border-width:         $modal-content-border-width;
$modal-footer-border-width:         $modal-header-border-width;
$modal-header-padding-y:            1.5rem;
$modal-header-padding-x:            1.8rem;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-xl:                          1140px;
$modal-lg:                          800px;
$modal-md:                          500px;
$modal-sm:                          300px;

$modal-fade-transform:              translate(0, -50px);
$modal-show-transform:              none;
$modal-transition:                  transform .3s ease-out;
