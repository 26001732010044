._news
{
    border-bottom:1px solid $color-gray-1;
}
._news_banner__img{
    //background-image: url('/img/thumb/news-banner.png');
}
//list
._news_list{
    position: relative;
    //padding:calcRem(150) 0;
    p{
        color: #666;;
    }
}
._news_list__section{
    padding:calcRem(75) 0;
    position: relative;
    color:$color-gray-5;
    

    &:first-child{
        padding-top: calcRem(150);
        @include media-breakpoint-down(lg) {
            padding: $main-pd-y-lg 0;
        }
        @include media-breakpoint-down(md) {
            min-height: unset;
        }
        @include media-breakpoint-down(sm) {
            padding: 10vw 0 0; 
        }
        @include media-breakpoint-down(xs) {
           
        }
    }
    @include media-breakpoint-down(lg) {
        
    }
    @include media-breakpoint-down(md) {
        
    }
    @include media-breakpoint-down(sm) {
        padding: 10vw 0 0; 
    }
    @include media-breakpoint-down(xs) {
       
    }
    
}
._news_list__year{
    font-size: calcRem(60);
    font-weight: bold;
    display: block;
    text-align: center;
    @include media-breakpoint-down(lg) {
        
    }
    @include media-breakpoint-down(md) {
        
    }
    @include media-breakpoint-down(sm) {
        font-size: calcRem(36);
    }
    @include media-breakpoint-down(xs) {
        font-size: calcRem(24);
    }
}
._news_list__row{
    @extend .d-flex;
    @extend .flex-wrap;
    &:nth-child(even){
        a{
            @extend .justify-content-end;
            
        }
        
    }
    
    padding-top: calcRem(100);

    a{
        @extend .d-flex;
        @extend .flex-wrap;
        width:100%;
        color:$color-gray-2;
        &:hover{
            color:#111111;
        }
    }
    @include media-breakpoint-down(lg) {
        
    }
    @include media-breakpoint-down(md) {
        padding-top: 8vw;
    }
    @include media-breakpoint-down(sm) {
        +._news_list__row{
            padding-top: 13vw;
        }
    }
    @include media-breakpoint-down(xs) {
       
    }
}
._news_list__card{
    width: 50%;
    @include media-breakpoint-down(lg) {
        
    }
    @include media-breakpoint-down(md) {
        
    }
    @include media-breakpoint-down(sm) {
        width:100%;

    }
    @include media-breakpoint-down(xs) {
       
    }
}
._news_list__mark{
    margin-bottom: calcRem(10);
    @include media-breakpoint-down(sm) {
        margin-bottom: 3vw;
    }
}
._news_list__title{
    font-size: calcRem(18);
    font-weight: bold;
    margin-bottom: calcRem(12);
    line-height: 1.4;
    @include media-breakpoint-down(sm) {
        font-size: calcRem(14);
        margin-bottom:2vw;
    }
    @include media-breakpoint-down(xs) {
        //font-size: calcRem(1);
    }
}
._news_list__cardCover{
    @include shadow-box;
    margin-bottom: calcRem(25);
    @include media-breakpoint-down(sm) {
        margin-top: 5vw;
    }
}
._news_bgn{
    position: absolute;
    top:0;
    width:100%;
    height: 100%;
    overflow: hidden;
}
._news_bgn__gray{
    position: absolute;
    svg{
        width: 100%;
    }
    &--1{
        width: 70%;
        left:0;
    }
    &--2{
        width: 85%;
        right:0;
        top:30%;
    }
}

._news_read{
    display: block;
    margin-top: calcRem(14);
    font-size: calcRem(12);
    //margin-bottom: calcRem(25);
}