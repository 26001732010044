._editor{
    
}
._editor_box{
    display: block;
}
._editor_container{
    @extend ._container;
    margin:calcRem(150) auto;
    
    @include media-breakpoint-down(lg) {
        margin: $main-pd-y-lg auto;
    }
    @include media-breakpoint-down(md) {
        min-height: unset;
    }
    @include media-breakpoint-down(sm) {
        margin: $main-pd-y-sm auto; 
    }
    @include media-breakpoint-down(xs) {
       
    }

    &--no-space{
        margin: 0 auto !important;
        padding: 0 !important;
    }
}
._editor_video{
    text-align: center;

    &--dark{
        background-color: #e4e4e4;
    }
}
.cke_contents{
    ._editor_video__iframe{
        width: 640px !important;
        height: 360px !important;
    }
}
._editor_video__iframe{
    position: relative;
    &::before{
        content:'';
        display: block;
        width: 100%;
        padding-top: 56.4%;
    }
    iframe{
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
    }
    img{
       &.cke_iframe{
           position: absolute;
           top:0;
           left:0;
       } 
    }
}

._editor_video__play{
    width: 85px;
    height: 85px;
    position: absolute;
    bottom:0;
    right:5%;
    border-radius: 100%;
    background-color: $color-red-main;
    border:none;
    box-shadow: 0px 30px 40px 0px rgba(0,0,0,0.28);
    transform: translateY(50%);
    display: none;
    &::before{
        content:'';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7.5px 0 7.5px 15px;
        border-color: transparent transparent transparent #ffffff;
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        margin:auto;
    }
}
._editor_video__des{
    margin-top: calcRem(20);
    @include media-breakpoint-down(sm) {
        margin-top: 2vw;
    }
    
}
._editor_imgSpec{
    text-align: center;
    line-height: 1.4;
    &--dark{
        background-color: $color-gray-3;
        ._editor_imgSpec__title{
            color:$color-white;
        }
        ._editor_imgSpec__des{
            color:$color-gray-4;
        }
    }
}
._editor_imgSpec__cover{
    margin-bottom: calcRem(25);
    img {
        width: 100%;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 2vw;
    }
}
._editor_imgSpec__title{
    font-size: calcRem(22) !important;
    font-weight: bold;
    
    margin-bottom: calcRem(10);
    @include media-breakpoint-down(sm) {
        margin-bottom: 2vw;
        font-size: calcRem(20) !important;
    }
    @include media-breakpoint-down(xs) {
        font-size: calcRem(16) !important;
    }
}
._editor_imgSpec__des{
    font-size: calcRem(16) !important;
    @include media-breakpoint-down(sm) {
        font-size: calcRem(14) !important;
    }
    @include media-breakpoint-down(xs) {
        font-size: calcRem(12) !important;
    }
}

._editor_hr{

}