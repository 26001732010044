._contact_banner__img{
   // background-image: url('/img/thumb/contact-banner.png');
}
//office
._contact_office{
    position: relative;
    padding:calcRem(150) 0;
    color:$color-gray-5;
    background-color: $color-gray-1;
    
    @include media-breakpoint-down(lg) {
        padding: $main-pd-y-lg 0;
    }
    @include media-breakpoint-down(md) {
        min-height: unset;
    }
    @include media-breakpoint-down(sm) {
        padding: $main-pd-y-sm 0; 
    }
    @include media-breakpoint-down(xs) {
       
    }
}
._contact_office__box{
    @extend .d-flex;
    @extend .flex-wrap;
}
._contact_office__btns{
    margin-left: auto;
}
._contact_office__title{
    font-size: calcRem(24);
    font-weight: bold;
    margin-bottom: calcRem(40);
    @include media-breakpoint-down(lg) {
        
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 5vw;
    }
    @include media-breakpoint-down(sm) {
        font-size: calcRem(18);
    }
    @include media-breakpoint-down(xs) {
        font-size: calcRem(16);
    }
}
._contact_office__item{
    font-size: calcRem(15);
    margin-bottom: calcRem(12);
    a{
        text-decoration: underline;
    }

    @include media-breakpoint-down(md) {
        font-size: calcRem(14);
    }
    @include media-breakpoint-down(xs) {
        font-size: calcRem(12);
    }
}
._contact_office__btns{
    @include media-breakpoint-down(lg) {
        width:100%;
        margin-top: calc(5vw);
        margin-left: unset;
    }
    @include media-breakpoint-down(md) {
        
    }
    @include media-breakpoint-down(sm) {
        
        
        
    }
    @include media-breakpoint-down(xs) {
       font-size: calcRem(12);
    }
}
._contact_office__link{
    @include shadow-box;
    text-align: center;
    font-size: calcRem(13);
    font-weight: bold;
    &:last-child{
        margin-left: calcRem(15);
    }
    @include media-breakpoint-down(sm) {
        margin-left: 0;
        width:100%;
        &:last-child{
            margin-left: 0;
            margin-top: 2vw;
        }
    }
}
._contact_office__map{
    overflow: hidden;
    display: block;
    position: relative;
    background-color: #000;
    &:hover{
        &::before{
            transform: scale(1.05,1.05);
            opacity: .6;
        }
        &::after{
            opacity: 1;
        }
    }
    &::before{
        content:'';
        display: block;
        padding-top: 35%;
        width:100%;
        background-image: url('/img/map.png');
        background-size: cover;
        background-position: center;
        transition-duration: .3s;
        
    }
    &::after{
        content:'Google Map';
        position: absolute;
        display: block;
        padding: 20px;
        color:$color-white;
        border:1px solid $color-white;
        min-width: 200px;
        top:50%;
        left:50%;
        text-align: center;
        transform: translate(-50%, -50%);
        transition-duration: .3s;
        opacity: 0;
        font-size: calcRem(14);
    }

    @include media-breakpoint-down(lg) {
        
    }
    @include media-breakpoint-down(md) {
        &::before{
            padding-top: 50%;
        }
    }
    @include media-breakpoint-down(sm) {
        
        &::before{
            background-size: 250%;
            padding-top: 60%;
        }
    }
    @include media-breakpoint-down(xs) {
        
        &::before{
            //background-size: 250%;
            //padding-top: 80%;
        }
    }
}