._worksDetail{
    
    line-height: 1.4;
}
._worksDetail_intro{
    background-color: #f5f5f5;
    color:#333;
    
}
._worksDetail_intro__container{
    padding:calcRem(150) 0;
    //min-height: 90vh;

    @include media-breakpoint-down(lg) {
        padding: $main-pd-y-lg 0;
    }
    @include media-breakpoint-down(md) {
        min-height: unset;
    }
    @include media-breakpoint-down(sm) {
        padding: $main-pd-y-sm 0; 
    }
    @include media-breakpoint-down(xs) {
       
    }
}
._worksDetail_intro__info{
    @include media-breakpoint-up(md) {
        width:60%;
        margin-top:0;
    }
}

._worksDetail_banner__img{
    &::after{
        content:'';
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        background-color:rgba(0,0,0,0.2);
        
    }
}
._worksDetail_intro__headline{
    //color: #fff;
    font-weight: bold;
    font-size:calcRem(18);
    margin-bottom: calcRem(20);
    @include media-breakpoint-down(md) {
        //font-size: calcRem(24);
    }
    @include media-breakpoint-down(sm) {
        font-size: calcRem(16);
        margin-bottom: 3vw;
    }
    @include media-breakpoint-down(xs) {
        font-size: calcRem(14)
    }
}
._worksDetail_intro__title{
    //font-size: calcRem(30);
    font-size: calcRem(30);
    //color:$color-white;
    //opacity: .6;
    font-weight:bold;
    //margin-bottom: calcRem(25);
    //margin-bottom: calcRem(5);
    @include media-breakpoint-down(md) {
        font-size: calcRem(24);
    }
    @include media-breakpoint-down(sm) {
        font-size: calcRem(20);
    }
    @include media-breakpoint-down(xs) {
        //font-size: calcRem(12);
    }
}
._worksDetail_intro__des{
    margin-bottom: calcRem(25);
    color:#5d5d5d;
    //color:$color-gray-4;
    @include media-breakpoint-down(sm) {
        font-size: calcRem(14);
        margin-bottom: 4vw;
    }
    @include media-breakpoint-down(xs) {
        font-size: calcRem(12)
        //margin-bottom: 2vw;
    }
}
._worksDetail_intro__link{
    width:100%;
    max-width: 280px;
    margin-top: calcRem(60);
    text-align: center;
}
._worksDetail_intro__lables{
    margin-top: calcRem(20);
}
._worksDetail_recommend{
    @extend .d-flex;
    @extend .align-items-stretch;
    @extend .flex-wrap;
    @extend .flex-md-nowrap;
}
._worksDetail_recommend__item{
    @extend .col-12;
    @extend .col-sm;
    padding:0;
    &:first-child{
        border-right:1px solid white;
    }
    @include media-breakpoint-down(sm) {
        border-right:none;
        border-top:1px solid white;
    }
}
._worksDetail_recommend__link{
    padding: calcRem(50);
    background-color: $color-gray-4;
    display: block;
    height:100%;
    text-align: center;
    color:$color-white;

    &:hover{
        color:$color-white;
        background-color: $color-gray-5;
    }

    @include media-breakpoint-down(sm) {
        padding:10%;
    }
}
._worksDetail_recommend__lable{
    font-size: calcRem(12);
    display: block;
    margin-bottom: clacRem(25);
    transform: scale(.9,.9);
}
._worksDetail_recommend__name{
    font-size: calcRem(16);
    span{
        padding-left: calcRem(10);
    }
    @include media-breakpoint-down(sm) {
        font-size: calcRem(14);
    }
}