._group_banner__img{
    border-bottom:1px solid $color-gray-1;
   // background-image: url('/img/thumb/group-banner.png');
}
//info
._group_info{
    position: relative;
    padding:calcRem(150) 0;
    color:$color-gray-5;
    position: relative;
    
    //min-height: 90vh;

    @include media-breakpoint-down(lg) {
        padding: $main-pd-y-lg 0;
    }
    @include media-breakpoint-down(md) {
        min-height: unset;
    }
    @include media-breakpoint-down(sm) {
        //padding: $main-pd-y-sm 0; 
        padding: 20vw 0; 
    }
    @include media-breakpoint-down(xs) {
        padding: 10vw 0; 
    }
    
}
._group_bgn{
    position: absolute;
    top:calcRem(80);
    right:0;
    width:65%;
    @include media-breakpoint-down(lg) {
        top:15vw;
        width:70%;
    }
}
._group_info__box{
    width:50%;
    @include media-breakpoint-down(md) {
        width:100%;
    }
    @include media-breakpoint-down(sm) {
        
    }
    @include media-breakpoint-down(xs) {
       
    }
}
._group_info__photo{
    @extend .d-flex;
    @extend .align-items-end;
    //margin-top: calcRem(200);
    margin-bottom: calcRem(100);
    @include media-breakpoint-down(lg) {
        margin-bottom: 10vw;
    }
    @include media-breakpoint-down(md) {
       // margin-top: 6vw;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 3vw;
    }
    @include media-breakpoint-down(xs) {
       
    }
}
._group_info__card{
    
    &--1{
        width: 45%;
        
    }
    &--2{
        //margin-top: calcRem(-0);
        width:55%;
        margin-left: 5%;
        margin-bottom: calcRem(150);
        //transform: translateY(55%);
    }
    &--3{
        //margin-top:calcRem(-50);
        right:0;
        position: relative;
        width:65%;
        margin-bottom: 0;
    }

    &--4{
        //margin-top:calcRem(-50);
        right:0;
        position: relative;
        width:40%;
        margin-left: 5%;
        margin-bottom: -15%;
        //margin-bottom: 0;
    }
    @include media-breakpoint-down(lg) {
        &--2{
            margin-bottom: 15vw;
        }
    }
    @include media-breakpoint-down(md) {
        &--2{
            margin-bottom: 10vw;
        }
        &--3{
            //width:100%;
        }
        &--4{
            margin-bottom: -6%;
        }
    }
    @include media-breakpoint-down(sm) {

        
        &--1{
            //width:48%
        }
        &--2{
            //margin-left: 4%; 
            //width:48%;
            //margin-top: 13vw;
        }
        &--3{
            margin-bottom: 5vw;
        }

        &--4{
            width:50%;
        }

        
        //transform: translate3d(0px, 0px, 0px) !important;
        
    }
    
}
._group_info__list{
    @extend .row;
    @extend .no-gutters;
    line-height: 1.6;
    margin-bottom: calcRem(150);

    @include media-breakpoint-down(md) {
        margin-bottom: 5vw;
    }
}
._group_info__listItem{
    @extend .col-12;
    @extend .col-lg;
    //@extend .px-md-3;
    

    padding: 0 2%;

    +._group_info__listItem{
        margin-top: calcRem(160);
        +._group_info__listItem{
            margin-top: calcRem(320);
        }
    }
    @include media-breakpoint-down(lg) {
        +._group_info__listItem{
            margin-top: 10vw;
            +._group_info__listItem{
                margin-top: 20vw;
            }
        }
    }
    @include media-breakpoint-down(md) {
        +._group_info__listItem{
            margin-top: 0;
            +._group_info__listItem{
                margin-top: 0;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        padding:0;
    }

}
._group_info__listTitle{
    //@extend ._font_title--xs;
    font-size:calcRem(18);
    color:$color-red-main;
    font-weight: bold;
    margin-bottom: calcRem(25);
    @include media-breakpoint-down(sm) {
        font-size:calcRem(16);
        margin-bottom:3vw;
    }
    @include media-breakpoint-down(xs) {
        font-size:calcRem(12);
        margin-bottom:2vw;
    }
}
._group_info__listDes{
    @include media-breakpoint-down(sm) {
        margin-bottom:5vw;
    }
}
._group_info__end{
    //background-color: $color-white;
    padding: 6% 0;
    width:40%;
    font-size:calcRem(18);
    line-height: 1.4;
    //margin-top: calcRem(-100);

    ._group_info__listDes{
        margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
        
        width:59%;
        
    }
    @include media-breakpoint-down(sm) {
        width:100%;
        margin-top: 5vw;
        padding: 0;
    }
    
}
._group_info__strong{
    @extend ._font_title--xl;
    color:$color-red-main;
    font-weight: bold;
    text-align: center;
    text-transform:uppercase;
    line-height: 1.2;
    //margin-top:calcRem(-80);
    margin-top:calcRem(220);
    margin-bottom: calcRem(150);

    @include media-breakpoint-down(lg) {
        font-size:font-vw-lg(45);
        margin-top:10vw;
        margin-bottom:10vw;
    }
    @include media-breakpoint-down(md) {
        font-size:font-vw-md(40);
        //margin-top: 15vw;

    }

    @include media-breakpoint-down(sm) {
        font-size:font-vw-sm(24);
        text-align: left;
        //margin-top: 10vw;
        br{
            display: none;
        }
        //line-height: 1.4;
    }
    @include media-breakpoint-down(xs) {
        font-size:calcRem(22);
    }

}
._group_info__spec{
    @extend ._font_des--normal;
    margin-top: calcRem(30);
    width:60%;
    display: block;
    line-height: 1.4;

    @include media-breakpoint-down(lg) {
        margin-top:8vw;
    }
    @include media-breakpoint-down(md) {
        font-size:font-vw-md(16);
        width:100%;
        margin-top:0;
    }
    @include media-breakpoint-down(sm) {
        font-size:calcRem(14);
        margin-top:6vw;
    }
    @include media-breakpoint-down(xs) {
        
    }
}
._group_info__footLine{
    @extend ._font_title--sm;
    color:$color-red-main;
    font-weight: bold;
    text-align: center;
    max-width: 800px;
    margin:auto;
    line-height: 1.4;
    margin-top: calcRem(500);
    margin-bottom: calcRem(250);


    @include media-breakpoint-down(lg) {
        margin-top:25vw;
    }
    @include media-breakpoint-down(md) {
        
        font-size:font-vw-md(40);
        margin-bottom: unset;
        margin-top:40vw;

    }
    @include media-breakpoint-down(sm) {
        //font-size:font-vw-md(24);
        text-align: left;
        margin-top: 10vw;
    }
    @include media-breakpoint-down(xs) {
        font-size:calcRem(22);
    }
}
._group_info__bgn{
    //background-image: url('../img/bgn-aobut.png');
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100vh;
    filter: unquote("grayscale(100%)");
    //opacity: .1;
    position: fixed;
    top:0;
    background-position-y: 0px;
    z-index: -1;
}
._group_info__bgnImg{
    position: absolute;
    width: auto;
    left:-1000%;
    right:-1000%;
    margin:auto;
    height:100vh;
}
//team
._group_team__list{
    @extend .d-flex;
    @extend .flex-wrap;
    background-color:$color-gray-2;
}
._group_team__item{
    width:20%;
    position: relative;
    @include media-breakpoint-down(lg) {
        
    }
    @include media-breakpoint-down(md) {
        
    }
    @include media-breakpoint-down(sm) {
        width:50%;
    }
    @include media-breakpoint-down(xs) {
        
    }

    &:hover{
        cursor: pointer;
        ._group_team__des{
            max-height: 80px;
        }
        ._group_team__cover{
            // &::before{
            //     background-color: rgb(202, 202, 202);
            //     opacity: .8;
            // }
            img{
                transform: scale(1.1,1.1);
                opacity: .95;
            }
        }
    }
}
._group_team__cover{
    @include creatCover(100%,100%);
    overflow: hidden;
    background-color: #000;
    //background-color: $color-red-sub-1;
    // &::before{
    //     position: relative;
    //     background-color: $color-red-sub-1;
    //     opacity: 1;
    //     transition-duration: .5s;
    // }
    img{
        @include absolue-center;
        position: absolute;
        height: 100%;
        width:auto;
        mix-blend-mode: screen;
        opacity: .75;
        z-index: 1;
        transition-duration: .5s;
    }
}
._group_team__link{
    color: #fff;
    &:hover{
        color: #fff;
    }
}
._group_team__info{
    position: absolute;
    bottom:0;
    padding: calcRem(20);
    line-height: 1.4;
    font-weight: bold;
    z-index: 2;

    

    @include media-breakpoint-down(sm) {
        padding:4vw;
    }
    @include media-breakpoint-down(xs) {
        //font-size:calcRem(22);
    }
    
}
._group_team__title{
    display: block;
    font-weight: normal;
    font-size: calcRem(13);
    @include media-breakpoint-down(sm) {
        
        font-size:calcRem(12);
    }
    @include media-breakpoint-down(xs) {
        //font-size:calcRem(22);
    }
    
}
._group_team__name{
    display: block;
    font-size: calcRem(24);
    @include media-breakpoint-down(md) {
        font-size:calcRem(18);
    }
    @include media-breakpoint-down(sm) {
        font-size:calcRem(16);
    }
    @include media-breakpoint-down(xs) {
        //font-size:calcRem(22);
    }
}
._group_team__des{
    display: block;
    font-size: calcRem(14);
    max-height: 0;
    overflow: hidden;
    transition-duration: .5s;
    @include media-breakpoint-down(sm) {
        display: none;
    }
}
._group_member{
    
    position: fixed;
    top:0;
    right:0;
    z-index: 999;
    color:$color-white;
    transition-duration: .3s;
    &.active{
        background-color:rgba(0,0,0,.6);
        ._group_member__box{
            transform: translateX(0);
        }
    }
  
}
._group_member__box{
    // @extend .d-flex;
    // @extend .justify-content-end;
    // @extend .align-items-stretch;
    width: 100vw;
    height:100vh;
    
    text-align: right;
    
    transition-duration: .5s;
    transform: translateX(calc(150vw + 100vh));
    @include media-breakpoint-down(sm) {
        transform: translateX(calc(300vh));
    }

    &::before{
        content:'';
        display: block;
        position: absolute;
        top:0;
        right:40%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 100vh 200vh;
        border-color:  transparent transparent $color-red-main transparent;
        @include media-breakpoint-down(lg) {
            right: 50%;
        }
        @include media-breakpoint-down(md) {
            //right: 50%;
        }
        @include media-breakpoint-down(sm) {
            right: 100%;
            //display: none;
        }
        @include media-breakpoint-down(xs) {
           
        }
    }
    
}
._group_member_content{
    position: relative;
    display: inline-block;
    text-align: left;
    overflow-y: auto;
    width: 40%;
    height: 100vh;
    background-color: $color-red-main;
    padding:calcRem(150) 8% calcRem(150) 3%;
    @include media-breakpoint-down(lg) {
        padding: $main-pd-y-lg 5%;
        width: 50%;
    }
    @include media-breakpoint-down(md) {
        
    }
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
    @include media-breakpoint-down(xs) {
       
    }
}
._group_member__job{
    font-size:calcRem(14);
    margin-bottom: calcRem(8);
    display: block;
    @include media-breakpoint-down(xs) {
        font-size:calcRem(12);
    }
}
._group_member__name{
    font-size:calcRem(48);
    margin-bottom: calcRem(60);
    font-weight: bold;
    @include media-breakpoint-down(sm) {
        font-size:calcRem(32);
        margin-bottom: 10%;
    }
    @include media-breakpoint-down(xs) {
        font-size:calcRem(26);
    }
}
._group_member__des{
    font-size:calcRem(16);
    line-height: 1.4;
    opacity: .8;
    @include media-breakpoint-down(xs) {
        font-size:calcRem(14);
    }
}
._group_member__bgn{
    position: absolute;
    top:0;
    left:0;
    width:calc(60vw);
    height:100%;
    pointer-events: none;
    transform: translateX(-100%);
    opacity: .1;
    display: none;
    img{
        object-fit: cover;
        position: absolute;
        top:-1000%;
        right:-1000%;
        left:-1000%;
        bottom:-1000%;
        margin: auto;
        width: auto;
        height:auto;
        min-width: 100%;
        min-height: 100%;
        filter: unquote("grayscale(100%)");
        mix-blend-mode: luminosity;
        //opacity: .15;
    }
}
._group_member__close{
    width:50px;
    position: absolute;
    right:50px;
    top:60px;
    @include media-breakpoint-down(sm) {
        width: 40px;
        right:5%;
        top:8vw;
    }
    @include media-breakpoint-down(xs) {
       
    }
}
._group_member__closeLine{
    &::before,&::after{
        width:100%;
    }
}