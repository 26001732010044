._btn{
    display: inline-block;
}
._btn_hollow{
    border: 1px solid;
    background-color:transparent;
    
    min-width: 150px;
    padding:calcRem(20);
    transition-duration: .3s;
    
    &--radius{
        border-radius: 100px;
    }

    &--red{
        border-color:$color-red-main;
        color: $color-red-main;
        &:hover{
            background-color: $color-red-main;
            color:$color-white;
        }
    }

    &--white{
        border-color:$color-white;
        color: $color-white;
        &:hover{
            background-color:$color-white;
            color:$color-gray-3;
        }
    }

    @include media-breakpoint-down(sm) {
        padding:calcRem(15) calcRem(20);
        min-width: unset;
    }

    @include media-breakpoint-down(xs) {
        padding:calcRem(13)  calcRem(18);
        
    }
}
._btn_solid{
    display: inline-block;
    padding:calcRem(20);
    min-width: 200px;
    transition-duration: .3s;
    position: relative;
    &::before{

    }
    &--white{
        background-color: #fff;
        &:hover{
            color:$color-gray-2;
        }
    }
    &--shadow{
        box-shadow: 0px 40px 60px 0px rgba(0,0,0,0.2);
    }
    &--hoverRed{

    }
    
    // &:hover{
    //     cursor: pointer;
    //     box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.2);
    // }
    @include media-breakpoint-down(sm) {
        //padding:calcRem(15);
        //min-width: unset;
    }

    @include media-breakpoint-down(xs) {
        //padding:calcRem(13);
        //width: 100%;
    }
}
._btn_solid__hover{
    span{
        position: relative;
        transition-duration: .5s;
    }
    &--bar{
        &::before{
            content:'';
            display: block;
            position: absolute;
            width:0%;
            height: 100%;
            background-color: $color-red-main;
            top:0;
            left:0;
            transition-duration: .3s;
        }
        

        &:hover{
            cursor: pointer;
            &::before{
                width: 100%;
            }
            span{
                color:$color-white
            }
        }
    }
}
._btn{
    
}