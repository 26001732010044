
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&family=Noto+Sans:wght@400;700&display=swap');

body{
    font-family: "Noto Sans TC","Yu Gothic", "PingFang TC", "微軟正黑體", Arial, sans-serif !important;
}
//title
._font_title{
   &--xl{
       font-size: calcRem(80);
   }
   &--md{
       font-size: calcRem(60);
   }
   &--sm{
       font-size:calcRem(50);
   }
   &--normal{
       font-size: calcRem(40);
   }
}

//btn
._font_btn{
    font-size: calcRem(14);
}

//des
._font_des{
    &--normal{
        font-size: calcRem(20);
    }
}