
html {
    //scroll-behavior: smooth;
    width:100% !important;
    height: 100vh;
    overflow-x: hidden;
  
    &.no-smooth {
      scroll-behavior: auto;
    }
    &.navOpen{
      overflow: hidden;
    }
    
  }
  
  body {
    font-size: #{$font-size-base}px;
  }
  
  .container {
    @include media-breakpoint-up(md) {
      width: 85%;
      //max-width: ;
    }
    padding:0 ;
    // &.maxw-1 {
    //   @include media-breakpoint-down(xxl) {
    //     max-width: 1280px !important;
    //   }
    // }
  
    // @include media-breakpoint-up(xxl) {
    //   max-width: 1345px;
    // }
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }
  
  input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  p{
    //color:#666;
  }
  
  a {
    text-decoration: none;
    color: #545454;
    transition: $transition-base;
  
    &:hover,
    &:active {
      text-decoration: none;
      color:#333;
    }
  }
  
  .btn-primary,
  .btn-secondary {
    color: $white;
  
    &:hover {
      color: $white;
    }
  }
  
  .btn-outline-secondary {
    color: darken($secondary, 33.3%);
  
    &:hover {
      background: lighten($secondary, 17%);
    }
  
    &:not(:disabled):not(.disabled) {
  
      &:active,
      &.active {
        background: none;
      }
    }
  }
  
  .btn-outline-primary {
    &:hover {
      color: $white;
    }
  
    &:not(:disabled):not(.disabled) {
  
      &:active,
      &.active {
        background: none;
      }
    }
  }
  
//   .btn-loading {
//     position: relative;
//     color: transparent !important;
  
//     &:after {
//       @extend ._loader;
//       display: block;
//       content: "";
//       position: absolute;
//       z-index: 0;
//       top: 0;
//       right: 0;
//       bottom: 0;
//       left: 0;
//       margin: auto;
//     }
//   }
  
  // modal.
  .modal-dialog {
    width: 95%;
    max-width: auro;
  
    &.modal-sm {
      max-width: $modal-sm;
    }
  
    &.modal-md {
      max-width: $modal-md;
    }
  
    &.modal-md2 {
      max-width: 650px;
    }
  
    &.modal-lg {
      max-width: $modal-lg;
    }
  
    &.modal-xl {
      max-width: $modal-xl;
    }
  
    &.modal-unlimited {
      max-width: auto;
    }
  }
  
  .modal-content {
    border: none;
    @extend .shadow;
  }
  
  .modal-header {
    background: $primary;
  
    @include media-breakpoint-down(xs) {
      //padding: calcRem(20);
    }
  
    .close {
      transition: $transition-base;
  
      @include media-breakpoint-down(xs) {
        //padding: calcRem(20);
        //margin: - calcRem(20);
      }
  
      &:hover {
        //background: $hover-color-primary-2;
      }
    }
  }
  
  .modal-footer {
    padding: 1.5rem 1.8rem;
  }
  
  .modal-title {
    color: $white;
    //font-size: calcRem(18);
    font-weight: bold;
    line-height: 1.3;
  }
  
  .modal-body {
    //padding: calcRem(20);
    word-break: break-all;
  
    @include media-breakpoint-up(sm) {
      //padding: calcRem(30);
    }
  
    @include media-breakpoint-up(sm2) {
      //padding: calcRem(40);
    }
  
    @include media-breakpoint-up(md) {
      //padding: calcRem(50);
    }
    
    form {
      >* {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  
  // checkbox.
  .form-check {
    //font-size: calcRem(13);
    display: flex;
    padding: 0;
    //margin: calcRem(25) 0;
    color: $black;
  
    a {
      color: $black;
  
      &:hover {
        color: $primary;
      }
    }
  
  }
  
  .form-check-box {
    display: flex;
    justify-content: center;
    align-items: center;
    //width: calcRem(30);
    //height: calcRem(30);
    border: $input-border-width solid $input-border-color;
    background: $input-bg;
  
    &:before {
      opacity: 0;
      display: block;
      content: "";
      width: 7px;
      height: 14px;
      border: solid #000;
      border-width: 0 2px 2px 0;
      transform: translate(5%, -15%) rotate(45deg);
      transition: $transition-base;
    }
  }
  
  .form-check-input {
    opacity: 0;
    //width: calcRem(30);
    //height: calcRem(30);
    left: 0;
    margin: 0;
    z-index: 2;
  
    &:checked {
      +span {
        &:before {
          opacity: 1;
        }
      }
    }
  }
  
  .form-check-label {
    //margin-left: calcRem(10);
    //margin-top: calcRem(6);
  }
  
  //////////border style///////////
  
  .border-dashed {
    border-style: dashed !important;
    border: 0;
  }
  
  .border-md-x {
    @include media-breakpoint-up(md) {
      border-left-width: 1px;
      border-right-width: 1px;
      border-color: #dee2e6;
    }
  }
  
  .border-md-left {
    @include media-breakpoint-up(md) {
      border-left-width: 1px;
      border-color: #dee2e6;
    }
  
    &:last-child {
      @include media-breakpoint-up(md) {
        border-right-width: 1px;
        border-color: #dee2e6;
      }
    }
  }
  
  .border-md-right {
    @include media-breakpoint-up(md) {
      border-right-width: 1px;
      border-color: #dee2e6;
    }
  
    &:first-child {
      @include media-breakpoint-up(md) {
        border-left-width: 1px;
        border-color: #dee2e6;
      }
    }
  }
  
  .border-sm2-bottom-0 {
    @include media-breakpoint-up(sm2) {
      border-bottom: 0px !important;
      border-color: #dee2e6;
    }
  }
  
  .border-sm2-right {
    @include media-breakpoint-up(sm2) {
      border-right: 1px solid #dee2e6;
  
    }
  }
  
  .border-sm2-left {
    @include media-breakpoint-up(sm2) {
      border-left: 1px solid #dee2e6;
  
    }
  }
  
  
  /////////row////////////
  .row-15 {
    display: flex;
    flex-wrap: wrap;
  
    .col-xs-3,
    .col-sm-3,
    .col-md-3,
    .col-lg-3 {
      position: relative;
      min-height: 1px;
      padding-right: 10px;
      padding-left: 10px;
    }
  
    .col-xs-3 {
      flex: 20%;
    }
  
    @media (min-width: 640px) {
      .col-sm2-3 {
        flex: 20%;
      }
    }
  
    @media (min-width: 768px) {
      .col-sm-3 {
        flex: 20%;
      }
    }
  
    @media (min-width: 992px) {
      .col-md-3 {
        flex: 20%;
      }
    }
  
    @media (min-width: 1200px) {
      .col-lg-3 {
        flex: 20%;
      }
    }
  
    .col-xs-6,
    .col-sm2-6 .col-sm-6,
    .col-md-6,
    .col-lg-6 {
      position: relative;
      min-height: 1px;
      padding-right: 10px;
      padding-left: 10px;
    }
  
    .col-xs-6 {
      flex: 40%;
    }
  
    @media (min-width: 640px) {
      .col-sm2-6 {
        flex: 40%;
      }
    }
  
  
    @media (min-width: 768px) {
      .col-sm-6 {
        flex: 40%;
      }
    }
  
  
    @media (min-width: 992px) {
      .col-md-6 {
        flex: 40%;
      }
    }
  
    @media (min-width: 1200px) {
      .col-lg-6 {
        flex: 40%;
      }
    }
  }
  
  ///form///
  
  .form-control--unset {
    padding: 0px;
    height: auto;
    width: auto;
  }