.pyro-image {
    width: 100%;
}
  
.cke_show_blocks {
    >._editor_block {
        border: 2px solid #fdb1b1 !important;
    }
    >p{
        border: 1px solid #7ecbdb !important;
        margin-bottom: 0;
    }
}

