._loader{
    position: fixed;
    top:0;
    left:0%;
    //z-index: 900;
    width: 100vw;
    height: 100vh;
    //transition-duration: .3s;
    opacity: 0;
    visibility: hidden;
    user-select:none;
    transition-delay: 2s;
    cursor:default;
    
    &.active{
        transition-delay: 0s;
        opacity: 1;
        visibility: visible;
        ._loader_txt{
            opacity: 1;
            visibility: visible; 
        }
    }
}
._loader_mask{
    width: calc(400vh + 100vw);
    height: 100vh;
    display:flex;
    transform-origin: 0 100%;
    //transform: skew(-63deg);
    position: absolute;
    top:0;
    left:100%;
    span{
       display: block;
       position: relative;
       width: 100vw;
       height: 100vh;
    }
    &::after{
        content:'';
        display: block;
        width: 0;
        height: 0;
        //position: absolute;
        left:100%;
        border-style: solid;
        border-width: 100vh 200vh 0 0;
        
    }
    &::before{
        content:'';
        display: block;
        width: 0;
        height: 0;
        //position: absolute;
        left:0%;
        border-style: solid;
        border-width:  0 0 100vh 200vh;
        border-color: transparent transparent   transparent;
        //transform: translate(-100%,0);
    }
    &--red{
        span{
            background-color:$color-red-main;
        }
        
        &::after{
            border-color: $color-red-main transparent transparent transparent;
        }
        &::before{
            border-color:  transparent transparent $color-red-main transparent;
        }
    }
    &--gray{
        span{
            background-color:$color-gray-3;
        }
        
        &::after{
            border-color: $color-gray-3 transparent transparent transparent;
        }
        &::before{
            border-color:  transparent transparent $color-gray-3 transparent;
        }
    }
    @include media-breakpoint-down(sm) {
        span{
            width: calc(100vw + 400px);
        }
    }
}
._loader_txt{
    font-size: calcRem(16);
    position: absolute;
    color:$color-white;
    display: inline-block;
    top:50%;
    left:50%;
    transition-duration: .5s;
    transform: translate(-50%,-50%);
    opacity: 0;
    visibility: hidden;


    @include media-breakpoint-down(sm) {
        font-size: calcRem(12);
    }
    
    span{
        //position:absolute;
        display: inline-block;
        opacity:0;
        //font-family:""Helvetica, Arial, sans-serif;
        animation:loading-move 1.5s linear infinite;
        //transform:rotate(180deg);
        margin: auto 2px;
        color:$color-white;
        &:nth-child(2) {
            animation-delay:.2s;
        }
        &:nth-child(3) {
            animation-delay:.4s;
        }
        &:nth-child(4) {
            animation-delay:.6s;
        }
        &:nth-child(5) {
            animation-delay:.8s;
        }
        &:nth-child(6) {
            animation-delay:1s;
        }
        &:nth-child(7) {
            animation-delay:1.2s;
        }
    }

    @keyframes loading-move {
        0% {
          left:-20;
          opacity:0;
          transition-timing-function: ease-in;
        }
        40% {
            left: 0%; 
            opacity:1;
        }
        60% {
            left:100%;   
            opacity:1;
        }
        100% {
            left:120%; 
            opacity:0;
            transition-timing-function: ease-out;
        }
    }
}
    
   