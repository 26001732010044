button{
    outline: none;    
}

._global_app{
    &.navOpen{
        overflow: hidden;
    }
}
._global_body{
    display: flex;
    flex-direction:column;
    min-height: 100vh;
}
._global_main{
    position: relative;
}
._global_footer{
    margin-top: auto;
}
._global__black{
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    top:0;
    left:0;
    opacity: 0;
    visibility: hidden;
    transition-duration: .5s;
    &.open,&.loading{
        opacity: 1;
        visibility: visible;
    }
}

img{ 
    transition-duration: .3s;
    &[data-src]{
        opacity: 0;
    }
    &[lazy="loaded"]{
        animation-name: fadein;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-direction: normal;
        animation-timing-function: ease-out;
        @keyframes fadein {
            0% {
            opacity: 0;
            }
            100% {
            opacity: 1;
            }
        }
    }
    &[lazy="loading"]{
        opacity: 0;
    }
    &.loading{
        opacity: 0 !important;
    }

    &.loaded{
        opacity: 0 !important;
    }
}