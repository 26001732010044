._home_banner__img{
    //background-image: url('/img/thumb/home-banner.png');
}
//info
._home_info{
    position: relative;
    padding:calcRem(150) 0;
    padding-bottom:calcRem(600);

    // background: url('/img/bgn-home-about.svg') no-repeat $color-white;
    // background-size:cover;
    //background-attachment: fixed;
    
    
    //min-height: 90vh;

    @include media-breakpoint-down(lg) {
        padding: $main-pd-y-lg 0;
    }
    @include media-breakpoint-down(md) {
        min-height: unset;
    }
    @include media-breakpoint-down(sm) {
        padding: $main-pd-y-sm 0;
        
    }
    @include media-breakpoint-down(xs) {
       
    }
}
._home_info__box{
    @extend .offset-lg-1;
    width: 55%;
    padding:0;
    @include media-breakpoint-down(md) {
        width: 70%;
    }
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}
._home_bgn{
    position: absolute;
    top:0;
    width:100%;
    height: 100%;
    overflow: hidden;

    
}
._home_bgn__red{
    position: absolute;
    right:0%;
    top:calcRem(200);
    width: 30%;
    svg{
        width: 100%;
    }
    @include media-breakpoint-down(md) {
        top: 43vw;
        width: 120%;
    }
}
._home_bgn__gray{
    position: absolute;
    left:0%;
    top:-100px;
    transform: translateY(-200px);
    width:50%;
}
//workd
._home_works{
   
    //background-color:;
    min-height: 100vh;
    padding-top: 1px;
    padding-bottom: calcRem(150);
    position: relative;
    background: url('/img/bgn-home-works.svg') no-repeat #ececec;
    background-position: center center;
    background-position-y: 0%;
    background-size: cover;
    background-attachment: fixed;
    z-index: 1;
    @include media-breakpoint-down(md) {
        margin-top: 7vw;
        padding-bottom: 10vw;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 0;
        padding: 10vw 0;
        min-height: unset;
        overflow: hidden;
    }
    @include media-breakpoint-down(xs) {
        margin-top: 0;
        padding: 10vw 0 20vw;
    }
}
._home_works__bgn{
    position: absolute;
    width: 100%;
    height:100%;
    overflow: hidden;
    
}
// ._home_works__bgnGray{
//     position: absolute;
//     &--1{
//         width:60%;
//         right:0;
//         top:500px;
//     }
//     &--2{
//         width:120%;
//         left:0;
//         top:-150px;
//     }
//     svg{
//         position: absolute;
//         width: 100%;
//     }
// }
._home_works__container{
    margin-top: -300px;
    @include media-breakpoint-down(lg) {
        margin-top: -7vw;
    }
    @include media-breakpoint-down(md) {
        margin-top: -7vw;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 0;
    }
    
}
._home_works__list{
    @extend .d-flex;
    @extend .flex-wrap;
    overflow: visible !important;
    @include media-breakpoint-up(md) {
        .swiper-wrapper{
            //@extend .d-flex;
            //@extend .flex-wrap;
            flex-wrap:wrap !important;
            transform:translate(0,0) !important;
        }
    }
    
    //@extend .no-gutters;
}
._home_works__itemBox{
    @include media-breakpoint-down(sm) {
        //transform: translate3d(0px, 0px, 0px) !important;
    }
}

._home_works__name{
    
}
._home_works__item{
    
    margin-bottom: calcRem(200);
    &:last-child{
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        &:nth-child(3n+1){
            // @extend .col-md-9;
            // @extend .offset-md-3;
            width:70%  !important;
            margin-left: 30% !important;
        }
        &:nth-child(3n){
            //@extend .col-md-5;
            //@extend .offset-md-2;
            width:46% !important;
            margin-left: 8% !important;
        }
        &:nth-child(3n+2){
            //@extend .col-md-5;
            width:46% !important;
            margin-top: calcRem(260) !important;
        }
        &:nth-child(6n+1){
            margin-left: 0 !important;
        }
        &:last-child{
            margin-bottom:0 !important;
            margin-top:0 !important;
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 7vw;
    }
    @include media-breakpoint-down(sm) {
        //margin-bottom: 0;
    }
    @include media-breakpoint-down(xs) {
        margin-bottom: 0;
        +._home_works__item{
            
            margin-top: 10vw;
        }
    }
    
}
._home_works__card{
    transition-duration: .3s;
    margin-bottom: calcRem(20);
    @include media-breakpoint-down(sm) {
        margin-bottom: 3vw;
    }
}

._home_works__cardImg{
    //opacity: 1;
}

._home_works__more{
    text-align: center;
    margin-top: calcRem(250);

    @include media-breakpoint-down(lg) {
        margin-top: 10vw;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 8vw;
    }
    @include media-breakpoint-down(xs) {
        margin-top: 20vw;
    }
}
._home_works__moreBtn{
    position: relative;
    font-size:calcRem(36);
    font-weight: bold;
    display: inline-block;
    color:$color-gray-2;
    transition-duration: .3s;
    //color:$color-white;
    &::after{
        content:'';
        display: block;
        height: calcRem(10);
        background-color: $color-red-main;
        margin-top: calcRem(20);
    }
    &::before{
        content:'';
        display: block;
        width: 0;
        height: calcRem(10);
        position: absolute;
        bottom:0;
        background-color: $color-white;
        transition-duration: .3s;
    }

    &:hover{
        color:$color-red-main;
        cursor: pointer;
        &::before{
            width:100%;
        }
    }

    @include media-breakpoint-down(lg) {
        font-size:calcRem(32);
        &::after{
            height: calcRem(8);
            margin-top: calcRem(15);
        }
        &::before{
            height: calcRem(8);
        }
    }

    @include media-breakpoint-down(md) {
        font-size:calcRem(24);
        &::after{
            height: calcRem(10);
            margin-top: calcRem(8);
            //margin-top: calcRem(10);
        }
        &::before{
            height: calcRem(10);
        }
    }

    @include media-breakpoint-down(sm) {
        font-size:calcRem(16);
        &::after{
            height: calcRem(5);
            //height: calcRem(5);
            //margin-top: calcRem(1);
        }
        &::before{
           // height: calcRem(5);
        }
    }
}
//news
._home_news{
    
}
._home_slide{
    //@extend .d-flex;
    background-color: $color-red-main;
    color:$color-white;

    >div{
        @extend .align-items-stretch;
    }
}
._home_news__list{
    @extend .d-flex;
    
}
._home_news__item{
    // @extend .col;
    height:auto !important;
    &:first-child{
       border:none; 
    }

    
    // @include media-breakpoint-down(md) {
    //     padding:8%;
    // }
}
._home_news__link{
    @extend .d-block;
    //@extend .flex-wrap;
    padding:calcRem(120) calcRem(90);
    border-left:1px solid rgba(255,255,255,.2);
    height: 100%;
    color:$color-white;
    transition-duration: .3s;

    &:hover{
        color:$color-white;
        background-color:rgba(255,255,255,.1);
    }
    @include media-breakpoint-down(lg) {
        padding:15%;
    }
    @include media-breakpoint-down(md) {
        padding:10%;
    }
    @include media-breakpoint-down(sm) {
        padding:12% 8%;
    }
    @include media-breakpoint-down(xs) {
        //padding:8%;
    }
}
._home_news__data{
    display: block;
    font-weight: bold;
    font-size:calcRem(13);
    margin-bottom: calcRem(30);
}
._home_news__title{
    display: inline-block;
    font-size:calcRem(14);
    line-height: 1.4;
    margin-right: 3px;
    @include media-breakpoint-down(xs) {
        font-size: calcRem(12);
    }
}
._home_news__point{
    margin-top: auto;
}