._container{
    width: 85%;
    position: relative;
    margin:auto;
    max-width: $container-max-widths;
    &--large{
        max-width: $container-max-widths-large;
    }
    &--small{
        max-width: $container-max-widths-small;
    }
    @include media-breakpoint-up(lg) {
        width: 70%;
    }
}