._tab{
    position: relative;
    @extend .d-flex;
    @extend .align-items-center;
    @include media-breakpoint-up(md) {
        &:hover{
            cursor: pointer;
        }
    }
}

._tab__item{
    font-size: calcRem(14);
    position: relative;
    margin-left: calcRem(20);
    &::after{
        content:'';
        display: block;
        height: 1px;
        width:100%;
        background-color: $color-red-main;
        margin-top: calcRem(10);
        transition-duration: .3s;
        //transform-origin: 50%;
        transform: scaleX(0);
    }

    &.active{
        &::after{
            transform: scaleX(1);
        }
    }

    @include media-breakpoint-up(800px) {
        font-size: calcRem(14);
        &::after{
            //margin-top: 3vw;
        }
    }
    @include media-breakpoint-up(480px) {
        font-size: calcRem(12);
        &::after{
           // margin-top: 2vw;
        }
    }
}