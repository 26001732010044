._card{
    &--hover{
        transition-duration: .3s;
        ._card_cover{
            transition-duration: .3s;
        }
        &:hover{
            ._card_cover--gray{
                filter: unquote("grayscale(0%)"); 
            }

        }
    }
}
._card_cover{
    overflow: hidden;
    margin-bottom: calcRem(20);
    position: relative;
    //transition-duration: .5s;
    background-color:$color-red-main;
    &::before{
        content:'';
        display: block;
        padding-top: 65%;
    }
    &--gray{
        filter: unquote("grayscale(100%)"); 
    }
    &--grayHover{
        &:hover{
            filter: unquote("grayscale(0%)"); 
        }
    }
    &:hover{
        img{
            // position: absolute;
            // top:-1000%;
            // right:-1000%;
            // bottom:-1000%;
            // left:-1000%;
            // margin: auto;
            // min-width: 100%;
            // min-height: 100%;
            // width: auto;
            // height: auto;
           // transform: scale(1.1,1.1);
        }
    }
    img{
        position: absolute;
        top:-1000%;
        right:-1000%;
        bottom:-1000%;
        left:-1000%;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: auto;
        object-fit: cover;
       // transform: scale(1.1,1.1);
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 3vw;
    }

}
._card_cover__img{
    width: 100%;
    height: auto;
    //transform:scale(1.3,1.3);
    opacity: 0;
    &[lazy="loaded"]{
        opacity: 1;
    }
    &:not(.initial) {
        transition: opacity 1s;
    }
    &:not([src]) {
        visibility: hidden;
    }
}

._card_title{
    margin-bottom: calcRem(6);
    &--white{
        color:$color-white;
    }
}

._card_mark{
    font-size: calcRem(13);
    font-weight: bold;
    display: block;
    margin-bottom: calcRem(20);
    &--red{
        color:$color-red-main;
    }
}

._card_des{
    font-size: calcRem(16);
    //margin-bottom: calcRem(15);
    //margin-top: calcRem(10);
    display: inline-block;
    line-height: 1.4;
    &--white{
        color:$color-white;
    }
    &--gray{
        color:$color-gray-6;
    }
    @include media-breakpoint-down(sm) {
        font-size: calcRem(14);
       // margin-bottom: 5vw;
    }
    @include media-breakpoint-down(xs) {
        font-size: calcRem(12);
        //margin-bottom: 0;
    }
}
._card_des__span{
    margin-left: calcRem(10);
}
._card_lables{
    font-size: calcRem(12);
    margin-top: calcRem(5);
    opacity: .7;
    span{
        display: inline-block;
        margin-right: calcRem(8);
    }
}
//icon
._card_icons{
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .align-items-center;
    margin-top:calcRem(30);
    @include media-breakpoint-down(md) {
        margin-top:5vw;
    }
    @include media-breakpoint-down(sm) {
        margin-top:3vw;
    }
}
._card_icons__icon{
    width:auto;
    height: calcRem(20);
    //background-color: $color-white;
    //border-radius: 100px;
    margin-right: calcRem(20);
    margin-bottom: calcRem(15);
    //margin-top: calcRem(5);
    position: relative;
    overflow: hidden;
    //padding: calcRem(7) 0;
    //border:1px solid $color-gray-4;
    svg,img{
        //@include absolue-center;
        height:100%;
        //width:60%;
       // min-width: 17px;
        //min-height: 17px;
    }
    
    
    @include media-breakpoint-down(md) {
        // width: 30px;
        // height: 30px;
    }
    @include media-breakpoint-down(sm) {
        // width: 5vw;
        // height: 5vw;
        // min-width: 24px;
        // min-height: 24px;
        svg{
            width:70%;
            height:24px;
        }
    }
    @include media-breakpoint-down(xs) {
        // width: 15px;
        // height: 15px;
        margin-right: calcRem(5);
        margin-top: 3%;
        margin-bottom:0;
    }
}
._card_icons__more{
    @extend .d-flex;
    margin-left: 3px;
    color:#fff;
    &::before,&::after{
        content:'';
        display: block;
        width:2px;
        height:2px;
        background-color: #fff;
        border-radius: 100%;
        margin: 1px;
    }
    span{
        display: block;
        border-radius: 100%;
        width:2px;
        height:2px;
        background-color: #fff;
        margin: 1px;

    }
}