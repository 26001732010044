//font
$font-size-base: 16;

//color
$color-red-main:#CA0B21;
$color-red-sub-1:#FF6363;

$color-white:#fff;

$color-gray-1:#F3F3F3;
$color-gray-2:#313131;
$color-gray-3:#232323;
$color-gray-4:#D6D6D6;
$color-gray-5:#494949;
$color-gray-6:#666;

$default-cover:#eee;

//space
$nav-height-lg:115px;
$container-pd-lg:50px;
$container-pd-sm:5%;
$main-pd-y-lg:15vw;
$main-pd-y-sm:10vw;



//
$container-max-widths:1200px;
$container-max-widths-large:1440px;
$container-max-widths-small:800px;

