._works{

}
._works_filter{
    @extend .d-flex;
}
._works_filter__category{
    border:1px solid #555;
}
._works_filter__order{
    margin-left: auto;
}
._works_filter__order{
    
}
._works_filter__orderItem{
    
}
._works_main{
    padding:calcRem(120) 0;
    min-height: 60vh;
    // background: url('/img/bgn-home-about.svg') no-repeat $color-white;
    // background-size:cover;
    //background-attachment: fixed;
    
    
    //min-height: 90vh;

    @include media-breakpoint-down(lg) {
        padding: $main-pd-y-lg 0;
    }
    @include media-breakpoint-down(md) {
        min-height: unset;
    }
    @include media-breakpoint-down(sm) {
        padding: $main-pd-y-sm 0;
        
    }
    @include media-breakpoint-down(xs) {
       
    }
}
._works_list{
    @extend .d-flex;
    @extend .flex-wrap;
    flex-direction: column;
    margin-top:calcRem(70);
    @include media-breakpoint-down(sm) {
        margin-top:7vw;
    }
    @include media-breakpoint-down(xs) {
        //margin-top:5vw;
    }
}
._works_list__row{
    @extend .d-flex;
    @extend .flex-wrap;
    position: relative;
    
    @include media-breakpoint-up(sm) {
        +._works_list__row{
            padding-top: 8%;
        }

        &:nth-child(3n){
            max-width: $container-max-widths-large;
        }
        &:nth-child(6n+1){
            ._works_list__rowItem{
                &:first-child{
                    width: 48%;
                }
                &:last-child{
                    width: 35%;
                    margin-left: 12%;
                    padding-top: 15%;
                }
            }
        }
        &:nth-child(6n+2){
            ._works_list__rowItem{
                &:first-child{
                    width: 35%;
                    margin-left: 5%;
                }
                &:last-child{
                    width: 48%;
                    margin-left: 12%;
                    padding-top: 15%;
                }
            }
        }
        &:nth-child(6n+3){
            align-items: center;
            //padding-top: 5%;
            ._works_list__rowItem{
                &:first-child{
                    
                    width: 60%;
                }
                &:last-child{
                    width: 30%;
                    margin-left: 10%;
                }
            }
        }
        &:nth-child(6n+4){
            ._works_list__rowItem{
                &:first-child{
                    width: 35%;
                    margin-left: 5%;
                    padding-top: 5%;
                }
                &:last-child{
                    width: 48%;
                    margin-left: 12%;
                    
                }
            }
        }
        &:nth-child(6n+5){
            
            ._works_list__rowItem{
                &:first-child{
                    padding-top: 10%;
                    width: 48%;
                }
                &:last-child{
                    width: 35%;
                    margin-left: 12%;
                }
            }
        }
        &:nth-child(6n){
            align-items: center;
            //padding-top: 10%;
            ._works_list__rowItem{
                &:first-child{
                    
                    width: 30%;
                }
                &:last-child{
                    width: 60%;
                    margin-left: 10%;
                    
                }
            }
        }
    }
}
._works_item{
    position: relative;
    width: 100%;
    @include media-breakpoint-down(sm) {
       margin-bottom: 10vw;
       //width: 50%;
    }
    @include media-breakpoint-down(xs) {
        //width: 100%;
    }
}

._works_item__cover{
    background-color: #f1f1f1;
    margin-bottom: calcRem(20);
    
    img{
        width:100%;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 3vw;
    }
}
._works_contact{
    background: url('/img/work-contact.png?v=1') no-repeat;
    background-size: cover;
}
._works_contact__container{
    padding: calcRem(150) 0;
    @include media-breakpoint-down(sm) {
        padding: 15vw 0;
    }
    @include media-breakpoint-down(xs) {
        //font-size:calcRem(16);
    }
}
._works_contact__text{
    font-size:calcRem(24);
    font-weight: bold;
    text-align: center;
    color:$color-white;
    line-height: 1.4;
    
    a{
        color:$color-white;
        text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
        margin-left:0;
        font-size:font-vw-sm(20);
        br{
            // display: none;
        }
    }
    @include media-breakpoint-down(xs) {
        font-size:calcRem(16);
    }
}