._header{
  
  width: 100%;
  align-items:center;
  position: fixed;
  padding:$container-pd-lg $container-pd-lg 0;
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
  height:$nav-height-lg;
  z-index: 999;
  box-sizing: border-box;

  // &::before{
  //   content:'';
  //   display: block;
  //   width: 100vw;
  //   height: 100vh;
  //   background-color:rgba(0,0,0,.6);
  //   position: absolute;
  //   top:0;
  //   left:0;
  //   visibility: hidden;
  //   opacity: 0;
  //   transition-duration: .3s;
  // }
  

  &::after{
    content:'';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 80px 0 0;
    border-color: $color-red-main transparent transparent transparent;
    position: absolute;
    top:0;
    left:0;
  }

  &.active{
    &::before{
      visibility: visible;
      opacity: 1;
    }
    ._header_trigger__icon{
        
    }
    ._header_trigger__iconLine--open{
      width:0;
      transition-delay: 0s;
    }
    ._header_trigger__iconLine--close{
      &::after,&::before{
        width:100%;
        transition-delay: .3s;
      }
    }


  }

  @include media-breakpoint-down(sm) {
    padding:$container-pd-sm $container-pd-sm 0;
    &::after{
      border-width: 6vw 12vw 0 0;
    }
  }

  @include media-breakpoint-down(xs) {
    padding:8vw $container-pd-sm 0;
    // &::after{
    //   border-width: 6vw 12vw 0 0;
    // }
  }
}

._header_container{
  @extend .d-flex;
  @extend .justify-content-center;
}

._header_bar{
  display: flex;
  align-items:center;
}
._header_logo{
  width:20%;
  max-width: 145px;
  min-width: 75px;
  position: relative;
  z-index:1;
  mix-blend-mode: difference;
  filter: drop-shadow(0 8px 8px px rgba(0, 0, 0, 0.6));
  svg{
    width: 100%;
  }
  &:hover{
    cursor: pointer;
  }

  &.open,&.loading{
    ._header_logo__txt{
      fill:$color-white;
    }
  }
  @include media-breakpoint-down(xs) {
    min-width: 50px;
  }
}
._header_logo__txt{
  //transition-duration: .3s;
  &.white{
    fill:$color-white;
  }
}
._header_trigger{
  margin-left: auto;
  width:50px;
  position: relative;
  @include media-breakpoint-down(md) {
    width:40px;
  }
  @include media-breakpoint-down(xs) {
    width:30px;
  }
}
._header_trigger__icon{
  display: block;
  width: 100%;
  height: 50px;
  //background-color: #ffcc00;
  flex:0 0 50px;
  margin-left: auto;
  position: absolute;
  top:0;
  bottom:0;
  right:0;
  margin:auto;
  z-index: 999;

  @include media-breakpoint-down(md) {
    height: 20px;
  }

  @include media-breakpoint-down(sm) {
    height: 15px;

    span{
      &::before{
        
        //transform: translateY(calc(-100% - 4px));
      }
      &::after{
        //transform: translateY(calc(100% + 4px));
      }
    }
  }

  @include media-breakpoint-down(sm) {
    height: 15px;

    span{
      height:1px;
      &::before{
        height:1px;
        //transform: translateY(calc(-100% - 4px));
      }
      &::after{
        height:1px;
        //transform: translateY(calc(100% + 4px));
      }
    }
  }
}

._header_trigger__iconLine{
  position: relative;
  display: block;
  width: 80%;
  
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  

  &:hover{
    cursor: pointer;
  }
  
  transition-duration: .3s;
  &--open{
    height: 2px;
    background-color: #fff;
    transition-delay: .3s;
    &::before,&::after{
      content:'';
      display: block;
      width:100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top:0;
      bottom:0;
      margin:auto;
      transition-duration: .3s;
      
    }
    &::before{
      transform: translateY(calc(-100% - 6px));
    }
    &::after{
      transform: translateY(calc(100% + 6px));
    }
  }

  &--close{
    height: 80%;
    transform: rotate(45deg);
    &::before,&::after{
      content:'';
      display: block;
      width:0%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      margin:auto;
      transition-duration: .3s;
      transition-delay: 0s;
    }
    &::before{
      transform: rotate(90deg);
    }
    &::after{
      //transform: translateY(calc(100% + 6px));
    }
  }
  
}